import React, { useState, useEffect } from 'react';

import { Card } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
// import Badge from '@material-ui/core/Badge';

import { Link } from 'react-router-dom';
// import * as IoIcons from 'react-icons/io';
import Styles, { Fab, LineMenuHome } from '../Styles'

import {
	api_bd,
	api_glpi
} from '../../services/Api'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'
import customerData from '../../atoms/customers.atom'
import clientDetails from '../../atoms/client_details.atom'
// import userTickets from '../../atoms/tickets.atom'
import navigationState from '../../atoms/navigate_state.atom'

import ListTickets from '../Tables/Tickets'

import '../Navbar.css';

import AvisoHome from '../../assets/aviso_home.jpeg'


// import LogoColor from '../../assets/logo_big_intec.png'
// import Modal from '../Modal'
// import FormDataUser from '../Forms/FormDataUser'
// import ModalTikets from '../ModalTickets'

const { backend_files_address } = require('../../config.json')

function CustomerList(props) {
	const styles = Styles();

	const [sgi_user, set_sgi_user] = useRecoilState(userState)

	const profile_data = useRecoilValue(profileData)

	const set_client_details = useSetRecoilState(clientDetails)

	const [data_home, set_data_home] = useRecoilState(customerData)

	const [navigation, set_navigation] = useRecoilState(navigationState)

	const [glpi_level, set_glpi_level] = useState('')

	const [device_height, set_device_height] = useState(null)

	const [loading_message, set_loading_message] = useState('Carregando informações, por favor aguarde.....')

	// const [show_modal, set_show_modal] = useState(false)
	// const [show_loading, set_show_loading] = useState(true)
	// const [show_modal_tickets, set_show_modal_tickets] = useState(false)
	// const [device_width, set_device_width] = useState(null)
	// const [tickets, set_tickets] = useRecoilState(userTickets)
	// const [glpi_user_name, set_glpi_user_name] = useState('')
	// const [glpi_user, set_glpi_user] = useState('')
	// const [user_sgi, set_user_sgi] = useState(null)


	useEffect(() => {
		set_client_details({})
		// const dw = document.documentElement.clientWidth
		const dh = document.documentElement.clientHeight
		// set_device_width(dw)
		set_device_height(dh)
	}, [])


	useEffect(() => {
		// console.log('Page HOME', profile_data, sgi_user)
		// console.log('ProfileData', profile_data)
		// console.log('UserData', sgi_user)
		set_glpi_level(profile_data.nivel)
	}, [sgi_user])


	// useEffect(() => {
	// 	(async () => {
	// 		// const data = await getUserData()
	// 		// set_user_sgi(data.user_sgi)
	// 		// set_glpi_user(data.glpiname)
	// 		// set_glpi_user_name(data.glpifirstname)


	// 	})()
	// }, [glpi_level])


	useEffect(() => {
		(async () => {
			set_loading_message('Carregando informações, por favor aguarde.....')
			if (profile_data.nivel === 'Cliente') {
				const dados = await api_bd.get(`/customers/glpiuser/${profile_data.username}`)
				if (dados.data.length > 0) {
					set_data_home(dados.data)
				} else {
					set_loading_message('Nenhuna Informação Cadastrada')
				}

			} else if (profile_data.nivel === 'Cliente-Admin') {
				const dados = await api_bd.get(`/customers/cliente-admin/${profile_data.id}`)
				if (dados.data.length > 0) {
					const ordered_data = dados.data ? await dados.data.sort((a, b) => a.nome.localeCompare(b.nome)) : []
					// set_data_home(dados.data)
					set_data_home(ordered_data)
				} else {
					set_loading_message('Nenhuna Informação Cadastrada')
				}

			} else if (profile_data.nivel.match(/Super|Técnico/i)) {
				const dados = await api_bd.get(`/customers`)
				if (dados.data.length > 0) {
					const ordered_data = dados.data ? await dados.data.sort((a, b) => a.nome.localeCompare(b.nome)) : []
					// set_data_home(dados.data)
					set_data_home(ordered_data)
				} else {
					set_loading_message('Nenhuna Informação Cadastrada')
				}
			}

		})()
	}, [glpi_level])


	return (
		<div className="flex flexRow height_less_header" style={{ width: "100%" }}>
			<>
				{data_home.length == 0 &&
					<div style={{
						display: "flex",
						flexWrap: "wrap",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						width: "100%"
					}}
						className="height_less_header"
					>
						<div className="flex" style={{ justifyContent: 'center', alignItems: 'center', width: '80%', maxWidth: '80%', height: '20vh', maxHeight: '20vh' }}>
							<div className="loader" style={{ width: 50, height: 50, marginRight: 10 }}></div>
							<h2>{loading_message}</h2>
						</div>

					</div>
				}

				{data_home.length > 0 &&
					<div style={{
						display: "flex",
						flexWrap: "wrap",
						flexDirection: 'row',//profile_data.nivel === 'Cliente' ? "column" : 'row',
						justifyContent: 'flex-start',//profile_data.nivel === 'Cliente' ? "center" : 'flex-start',
						alignItems: 'flex-start',//profile_data.nivel === 'Cliente' ? "center" : 'flex-start',
						width: "100%"
					}}
						className="height_less_header"
					>
						{profile_data.nivel === 'Cliente' &&


							<div className="flex flexWrap height_less_header" style={{
								width: "100%",
								alignItems: "flex-start"
							}}>
								{/* <img style={{ width: '100%' }} src={AvisoHome} /> */}
								<ListTickets />
							</div>
						}

						{(profile_data.nivel.match(/Super|Técnico|Cliente-Admin/i)) &&
							<>
								<div style={{ maxHeight: 50, paddingLeft: 30, marginTop: 20, marginBottom: 10, width: "100%" }}>
									{profile_data.nivel === 'Cliente-Admin' && <h3>LISTA DE ACESSOS</h3>}
									{(profile_data.nivel.match(/Super|Técnico/i)) && <h3>LISTA DE CLIENTES</h3>}
								</div>
								<div className="flex flexWrap height_less_header" style={{ overflowY: "auto", paddingBottom: 70 }}>
									{data_home.length > 0 && data_home.map((el) => (
										<Card key={el._id} className={styles.card} style={{ width: 180, height: 200 }}>
											<Link
												onClick={() => set_client_details(el)}
												to={{ pathname: 'clientdetails', state: { data_client: el } }}
											// to='clientdetails'
											>
												<CardContent className="flex flexCol justify-content-sb" style={{ height: "100%" }} >
													<img style={{ borderRadius: 5 }} className={styles.imgCard} src={el.logomarca.filename == "" ? `${backend_files_address}/default-logo.png` : `${backend_files_address}/${el.logomarca.filename}`} />
													<CardHeader
														style={{ textAlign: "center", padding: 0 }}
														// title={el.nome}
														subheader={el.nome}
													/>
												</CardContent>
											</Link>
										</Card>
									))}
								</div>
							</>
						}
					</div>
				}
			</>
		</div>

	);
}

export default CustomerList;


