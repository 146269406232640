import React from 'react';

import TextField from '@material-ui/core/TextField';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles({
    label: {
        color: '#fff',
    },
    bglabel: {
        color:'#fff'
    },
    border: {
        color:'#fff',
        borderBottomStyle:'#fff 1px solid',
        backgroundColor: '#fff'
    },
});

function InputAutoComplete(props) {
    const classes = useStyles();
    return (
        <div style={props._style ? props._style : { width: "100%" }}>
            <Autocomplete
                options={props.data ? props.data : []}
                getOptionLabel={ (option) => `${option[props.OptionLabel ? props.OptionLabel : 'label']}` }
                id="localidade"
                autoComplete
                includeInputInList
                classes={{
                    root: classes.root,
                    inputRoot: classes.label,
                    popupIndicator: classes.bglabel,
                    popupIndicatorOpen: classes.bglabel,
                    clearIndicator: classes.bglabel,
                    inputFocused: classes.bglabel
                }}
                renderInput={(params) => <TextField {...params} label={props.label ? props.label : ''} margin="normal" />}
                onChange={e => {
                    props.returnData ? props.returnData(e.target.innerText ? e.target.innerText : "") : console.log('Retorno: ', e.target.innerText ? e.target.innerText : "")
                }}
            />
        </div>
    );
}

export default InputAutoComplete;