import { api_bd } from './Api'

export const logoff = () => ({ sgi_data: {} })

export const login = async (_user, _pass) => {
	// console.log(_user, _pass)
	const usernamePasswordBuffer = Buffer.from(_user + ':' + _pass);
	const base64data = usernamePasswordBuffer.toString('base64');

	const user_login = await api_bd.get('/users/login/', {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Basic ${base64data}`
		}
	})
	// console.log('services login:', user_login.data)

	return user_login.data
}

export default login;