import React, { useEffect, useState } from 'react';

import axios from 'axios'

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import TextField from '@material-ui/core/TextField';

import { api_bd } from '../../services/Api'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


import { Bar } from 'react-chartjs-2';

const { api_backend_address } = require('../../config.json')

function TopTickets() {

    const [data_chart, set_data_chart] = useState({})
    const [data_table, set_data_table] = useState([])


    const [device_height, set_device_height] = useState(null)
    const [device_width, set_device_width] = useState(null)

    useEffect(() => {
        (async () => {
            const t_ch = await api_bd.get(`${api_backend_address}tickets/top2`)
            set_data_table(t_ch.data)
            console.log(t_ch.data)

            let dados = {
                labels: [],
                datasets: [
                    {
                        label: 'Chamados',
                        data: [],
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                        borderWidth: 1,
                    },
                ],
            };

            Object.entries(t_ch.data[0]).map(el => {
                console.log(el)
                dados.labels.push(el[0])
                dados.datasets[0].data.push(el[1])
            })

            set_data_chart(dados)
            
        })()

        const dw = document.documentElement.clientWidth
        const dh = document.documentElement.clientHeight
        set_device_width(dw)
        set_device_height(dh)
    }, [])

    /*
    const data_chart = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'JONAS LINDO!'],
        datasets: [
            {
                label: '# of Votes',
                data: [12, 19, 3, 5, 2, 3, 9],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
    */

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center' , minWidth: (device_width - 210) * 0.96, maxWidth: (device_width - 210) * 0.96, padding: 15, minHeight: `${device_height+50}px`, maxHeight: `${device_height+50}px`, overflowY: 'auto' }}>            
            {/* <div style={{ width: "100%", marginTop: 20 }}>
                <div className='header'>
                    <h1 className='title'>CHAMDOS POR CLIENTE</h1>
                    <div className='links'>
                    </div>
                </div>
                { false && data_chart && Object.keys(data_chart).length > 0 &&
                    <Bar data={data_chart} options={options} />
                }
            </div> */}

            <TableContainer component={Paper} style={{  width: '70%',  maxWidth: '70%', minWidth: '70%', border: '1px solid #fff' }}>
                <Table>
                    <TableHead style={{ backgroundColor: '#3c5ca6', borderRadius: 5 }}>
                        <TableRow>
                            <TableCell style={{ padding: 5, color: '#fff', border: '2px solid #fff', borderTopLeftRadius: 10  }} align="center">Requerente</TableCell>
                            <TableCell style={{ padding: 5, color: '#fff', border: '2px solid #fff', width: 80 }} align="center">Qtd de Chamados</TableCell>
                            <TableCell style={{ padding: 5, color: '#fff', border: '2px solid #fff', width: 80, borderTopRightRadius: 10  }} align="center">Total de Horas</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ backgroundColor: '#e3e3e3', borderRadius: 5 }}>
                        {data_table && data_table.length > 0 && Object.entries(data_table[0]).map(el =>(
                                <TableRow>
                                    <TableCell style={{ padding: 5, border: '2px solid #fff' }} align="center">{el[0]}</TableCell>
                                    <TableCell style={{ padding: 5, border: '2px solid #fff' }} align="center">{el[1]}</TableCell>
                                    <TableCell style={{ padding: 5, border: '2px solid #fff', width: 80 }} align="center">Horas</TableCell>
                                </TableRow>
                            ))
                        }
                        
                    </TableBody>
                </Table>
            </TableContainer>

            
        </div>
    );
}

export default TopTickets;