import React, {
    useState,
    // useEffect 
} from 'react';
import { useHistory } from 'react-router-dom';

import { useRecoilState, useSetRecoilState } from 'recoil'

import { makeStyles } from '@material-ui/core/styles';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';

import Card from '@material-ui/core/Card';
// import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Logo from '../assets/logo_big_intec.png'
import NewLogo from '../assets/logo_sgi_intec.png'
import Logo_mini from '../assets/logo_mini_intec.png'
import Bg_logo from '../assets/intec_bg_login.jpg'

import login from '../services/login'


import userState from '../atoms/user.atom'


const Styles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    div_root: {
        paddingTop: '10px',
        paddingLeft: "5%",
        paddingRight: "5%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundImage: `url(${Bg_logo})`
    },
    form_cad_user: {
        width: "100%"
    },
    form_input: {
        width: "300px"
    },
    form_select: {
        width: "200px"
    },
    btn_submit: {
        marginTop: '20px',
        marginLeft: '0px',
        paddingRight: "25px",
        marginBottom: "20px"
    },
    card: {
        borderRadius: 10,
        paddingTop: 5,
        minWidth: 275,
        color: "#3c5ca6"
    }
}));

function Login({ setToken }) {
    const styles = Styles();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const set_user = useSetRecoilState(userState)

    const history = useHistory()

    return (
        <div
            className={styles.div_root}
            onKeyUp={async (e) => {
                if (e.keyCode === 13) {
                    const loginuser = await login(email, password)
                    // console.log(loginuser)
                    if (loginuser.sgi_data !== null) {
                        set_user(loginuser)
                        history.push('/')
                    } else {
                        alert('Usuário ou senha incorreto, verifique os dados e tente novamente.')
                    }
                }
            }}
        >
            <Card className={styles.card}>
                <CardContent style={{ textAlign: "center", padding: 0 }}>
                    <div style={{ backgroundColor: '#3c5ca6', width: "100%", marginTop: -7 }}>
                        {/* <img src={Logo_mini}
                                style={{
                                    margin: 10,
                                    maxHeight: 40
                                }} /> */}
                        <h2 style={{ padding: 20, color: '#fff', fontSize: '1.3em' }}>SUA LOGO</h2>
                    </div>
                    <img src={NewLogo}
                        style={{
                            marginTop: 30,
                            width: 180
                        }} />
                    <form
                        className={styles.form_cad_user}
                        noValidate
                        autoComplete="off"
                        style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 30, paddingTop: 30 }}>
                        <div>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    setEmail(e.target.value)
                                }}
                                id="usuario"
                                label="Usuário"
                            />
                        </div>
                        <div>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    setPassword(e.target.value)
                                }}
                                type="password"
                                autoComplete="current-password"
                                id="password"
                                label="Senha"
                            />
                        </div>
                        <div>
                            <Button
                                className={styles.btn_submit}
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={async e => {
                                    const loginuser = await login(email, password)
                                    // console.log(loginuser)
                                    if (loginuser.sgi_data !== null) {
                                        set_user(loginuser)
                                        history.push('/')
                                    } else {
                                        alert('Usuário ou senha incorreto, verifique os dados e tente novamente.')
                                    }
                                }}
                            >
                                Login
                            </Button>
                        </div>

                    </form>
                </CardContent>
            </Card>
        </div>
    );
}

export default Login;