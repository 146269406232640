import React, {
    useState,
    useEffect
} from 'react';

// import { makeStyles } from '@material-ui/core/styles';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';



import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
// import FormLabel from '@material-ui/core/FormLabel';


// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';

// import Card from '@material-ui/core/Card';
// import CardHeader from '@material-ui/core/CardHeader';
// import CardContent from '@material-ui/core/CardContent';

import Styles, { primary_color } from '../Styles'
import Modal from '../Modal'
import Toast from '../Toast'
import FlowNodes from '../FlowNodesComponent';

import { api_bd } from '../../services/Api'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'
import navigationState from '../../atoms/navigate_state.atom'
import openTreatment from '../../atoms/open_treatment';

import FileUploaderMulti from '../FileUploaderMulti';
import FileDropzone from '../DropZone';

import TicketPopup from '../TicketPopup';

import NivelDeAcesso from '../NivelDeAcesso';

import { backend_files_address, backend_ticket_files, data_labels, tipos_de_solicitacao, categorias, subcategorias, tipos, tipos_de_ativos, tipos_de_alteracoes, situacoes_do_servico, tipos_situacao } from '../../config.json'// = require('../../config.json')
import { css } from 'styled-components';

function TicketDetail(props) {
    const { width } = props

    const styles = Styles();

    const [lista_chamados, set_lista_chamados] = useState([])

    const [lista_usuarios, set_lista_usuarios] = useState([])

    const [device_height, set_device_height] = useState(null)

    // const [device_width, set_device_width] = useState(null)

    const navigation_state = useRecoilValue(navigationState)

    const set_navigation_state = useSetRecoilState(navigationState)

    const user_profile = useRecoilValue(profileData)

    const [show_input_text, set_show_input_text] = useState(false)

    const [text_interacao, set_text_interacao] = useState('')

    const [show_modal_pendencia, set_show_modal_pendencia] = useState(false)

    const [show_toast, set_show_toast] = useState(false)
    const [content_toast, set_content_toast] = useState(null)


    const [situacao_chamado, set_situacao_chamado] = useState(navigation_state?.data?.situacao)
    const [motivo_da_situacao, set_motivo_da_situacao] = useState(navigation_state?.data?.situacao?.descricao)

    const [tempo_de_execucao, set_tempo_de_execucao] = useState(null)
    const [tratativa_aberta, set_tratativa_aberta] = useRecoilState(openTreatment)

    const [anexo_interacao, set_anexo_interacao] = useState(false)

    const [solucao_ticket, set_solucao_ticket] = useState(false)

    const [meu_chamado, set_meu_chamado] = useState(false)
    const [qtd_tecnicos, set_qtd_tecnicos] = useState(0)


    const [tipo_interacao, set_tipo_interacao] = useState('cliente')


    const [show_modal_categorizacao, set_show_modal_categorizacao] = useState(false)

    const [show_form_agendamento_janela, set_show_form_agendamento_janela] = useState(false)

    const [options_modal, set_options_modal] = useState({
        show: false,
        content: null
    })

    const formatDate = (date_str) => {
        const date = new Date(date_str);  // dateStr you get from mongodb
        const dia = date.getDate();
        const mes = date.getMonth() + 1;
        const ano = date.getFullYear();

        const hora = date.getHours();
        const minuto = date.getMinutes();
        //return {dia, mes, ano, hora, minuto}
        return `${dia < 10 ? '0' + dia : dia}/${mes < 10 ? '0' + mes : mes}/${ano} às ${hora < 10 ? '0' + hora : hora}:${minuto < 10 ? '0' + minuto : minuto}`
    }


    const Form_Agendamento = (props) => {

        const [dados_agendamento, set_dados_agendamento] = useState({
            nome: "",
            contato: "",
            feriado: false,
            janela_manutencao: true,
            descricao: "",
            data: ""
        })

        const handle_agendamento = (key, dados) => {
            let temp = { ...dados_agendamento }
            temp[key] = dados
            set_dados_agendamento(temp)
        }

        return (
            <div className={styles.div_root_component} style={{ backgroundColor: '#fff', borderRadius: 10, width: '50%', padding: 60 }}>
                <div style={{ fontSize: '1.5em', marginBottom: 30 }}>
                    Defina os dados do agendamento da janela.
                </div>
                <TextField
                    style={{ width: '100%' }}
                    id="date"
                    label="Calendário"
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                        handle_agendamento('data', new Date(`${e.target.value} 00:00:00`))
                    }}
                />

                {lista_usuarios && lista_usuarios.length > 0 &&
                    <FormControl style={{ width: '100%', marginTop: 20 }}>
                        <InputLabel htmlFor="colaborador">Colaborador</InputLabel>
                        <Select
                            // value={new_date.idu}
                            id="colaborador"
                            onChange={e => {
                                const usuario = lista_usuarios.filter(user => user.id === e.target.value)
                                const { id, nome, contato } = usuario[0]
                                let temp = { ...dados_agendamento }
                                temp['idu'] = id
                                temp['nome'] = nome
                                temp['contato'] = contato
                                set_dados_agendamento(temp)
                            }}
                        >
                            {lista_usuarios.filter(user => (user.nivel.match(/Super|Técnico/) && user.ativo === true)).map(user => (<MenuItem value={user.id}>{user.nome}</MenuItem>))}
                        </Select>
                    </FormControl>
                }

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%', marginTop: 20 }}>
                    <TextField
                        className={styles.form_input}
                        onChange={e => {
                            handle_agendamento('descricao', e.target.value)
                        }}
                        label="Descrição"
                        value={dados_agendamento.descricao}
                        id="descricao"
                        multiline={true}

                    />
                </div>


                <Button
                    style={{ width: '100%', marginTop: 15 }}
                    className={styles.btn_submit}
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={(dados_agendamento.data !== '' && dados_agendamento.nome !== '') ? false : true}
                    onClick={async () => {
                        try {
                            const resp = await api_bd.post('/suport_calendar', { ...dados_agendamento, descricao: `ID do Chamado: ${navigation_state.data.cod_id} - ${dados_agendamento.descricao}` })
                            set_show_form_agendamento_janela(false)
                            set_content_toast(<span style={{ fontSize: '1.4em' }}>Evento de janela de manutenção foi criado no calendário.</span>)
                            set_show_toast(true)
                            setTimeout(() => {
                                set_content_toast(null)
                                set_show_toast(false)
                            }, 5000)
                        } catch (error) {

                        }
                        // set_dados_agendamento({
                        // 	idu: '',
                        // 	data: '',
                        // 	nome: '',
                        // 	contato: '',
                        // 	feriado: false,
                        // 	janela_manutencao: true,
                        // 	descricao: ''
                        // })
                    }}
                >
                    Salvar
                </Button>
            </div>
        )
    }

    const Descricao = (props) => {
        const { dados, labels } = props
        return Object.keys(dados).map(el => {

            if ((el !== 'situacao' && el !== 'prioridade' && el !== 'titulo' && el !== 'tipo_solicitacao' && el !== 'categoria' && el !== 'subcategoria' && el !== 'nome' && el !== 'anexo') && data_labels[el] && dados[el] !== '' && dados[el] !== undefined) {
                let valor_field = ''
                switch (el) {
                    case 'tipo_solicitacao':
                        valor_field = tipos_de_solicitacao[dados[el]]
                        break;

                    case 'categoria':
                        valor_field = categorias[dados[el]]
                        break;

                    case 'subcategoria':
                        valor_field = subcategorias[dados[el]]
                        break;

                    case 'tipo':
                        valor_field = tipos[dados[el]]
                        break;

                    case 'tipo_ativo':
                        valor_field = tipos_de_ativos[dados[el]]
                        break;

                    case 'tipo_alteracao':
                        valor_field = tipos_de_alteracoes[dados[el]]
                        break;

                    case 'situacao_do_servico':
                        valor_field = situacoes_do_servico[dados[el]]
                        break;

                    case 'detalhes_solicitacao':
                        valor_field = <pre>{dados[el]}</pre>
                        break;

                    case 'nome':
                        valor_field = `${dados[el]} (${dados.customer_name})`
                        break;

                    default:
                        valor_field = dados[el]
                        break;
                }

                return (
                    <p key={el} style={{ marginTop: 5 }}>
                        <b style={{ fontSize: '0.9em' }}>{labels[el].toUpperCase()}:</b> <span style={{ marginLeft: 0, fontSize: '0.9em' }}>{valor_field}</span>
                    </p>
                )
            }
        })
    }

    const modal_close = async () => {
        const sc = { titulo: situacao_chamado.titulo, descricao: motivo_da_situacao }
        set_situacao_chamado(sc)
        set_show_modal_pendencia(false)

        const { ...data } = navigation_state.data
        data.situacao = sc
        if (sc.titulo == 'pendente_outros') {
            const dt_followup = new Date()
            dt_followup.setDate(dt_followup.getDate() + 3)
            data.followup = { state: true, reopen_date: dt_followup }
        }

        const resp_data = await api_bd.put(`/tickets/${data._id}`, data)
        set_navigation_state({ page_content: 'ticket_detail', data: resp_data.data[0] })
    }

    const addRespTec = async (_id_ticket, _user) => {
        const resp = await api_bd.put(`/tickets/getforme/${_id_ticket}`, _user)
        set_navigation_state({ page_content: 'ticket_detail', data: resp.data[0] })
        set_situacao_chamado(resp.data[0].situacao)
    }

    const removeRespTec = async (_id_ticket, _user) => {
        const resp = await api_bd.put(`/tickets/removeresp/${_id_ticket}`, _user)
        set_navigation_state({ page_content: 'ticket_detail', data: resp.data[0] })
    }

    const show_toast_users = async () => {
        const tecnicos_disponiveis = await lista_usuarios.filter(user => {
            // console.log('Condição: ', navigation_state.data.resposaveis_tecnicos.find(el => (el.id === user._id)))
            if (!navigation_state.data.resposaveis_tecnicos.find(el => (el.id === user._id))) {
                return (user.nome)
            } else {
                return false
            }
        })

        if (tecnicos_disponiveis.length > 0) {
            const contenttoast = <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <p style={{ marginBottom: 10 }}><b>Qual usuário deseja adicionar a este chamado?</b></p>
                {lista_usuarios.map(user => {
                    //console.log(navigation_state.data.resposaveis_tecnicos.find(el => (el.id === user._id)) ? false : true)
                    if (!user.nivel.match(/Cliente/)) {
                        return ((navigation_state.data.resposaveis_tecnicos.find(el => (el.id === user._id)) ? false : true) &&
                            <div
                                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#8595ff', padding: 10, borderRadius: 35, margin: 5, color: '#fff', fontWeight: 'normal', cursor: 'pointer' }}
                                onMouseOver={(e) => {
                                    e.target.style.backgroundColor = '#00f4'
                                    for (let i = 0; i < e.target.children.length; i++) {
                                        e.target.children[i].style.backgroundColor = ''
                                    }
                                }}

                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = '#8595ff'
                                    for (let i = 0; i < e.target.children.length; i++) {
                                        e.target.children[i].style.backgroundColor = ''
                                    }

                                }}

                                onClick={(e) => {
                                    addRespTec(navigation_state.data._id, user)
                                    set_show_toast(false)
                                    set_content_toast(null)
                                }}
                            >
                                <div
                                    style={{ marginTop: 5, marginBottom: 5, fontSize: '1.1em' }}
                                >
                                    {user.nome.toUpperCase()}
                                </div>
                                <img style={{ width: 50, height: 50, objectFit: 'cover', borderRadius: 25, border: '#c3c3c3 solid 2px' }} src={`${(user.image_profile.filename !== '') ? backend_files_address + '/' + user.image_profile.filename : 'https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png'} `} />
                            </div>
                        )
                    }
                })}

                <Button
                    className={styles.btn_submit}
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ width: '80%', alignSelf: 'center', height: 30 }}
                    onClick={() => {
                        set_show_toast(false)
                        set_content_toast(null)
                    }}>
                    Fechar
                </Button>
            </div>
            set_content_toast(contenttoast)
            set_show_toast(true)
        } else {
            const contenttoast = <div>
                <p>
                    <b>Todos os técnicos ja foram adicionados a este chamado.</b>
                </p>
                <Button
                    className={styles.btn_submit}
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ width: '100%' }}
                    onClick={() => {
                        set_show_toast(false)
                        set_content_toast(null)
                    }}>
                    Fechar
                </Button>
            </div>
            set_content_toast(contenttoast)
            set_show_toast(true)
        }

    }

    const FormatRespTec = (props) => {
        const qtd_tec = props.dados.length
        const tecnicos = qtd_tec < 3
            ?
            props.dados.map(tech => (<div style={{ padding: 2, borderRadius: 3, border: '1px grey solid', marginLeft: 5, marginTop: -2, fontSize: '1em', position: 'relative' }}>
                {(
                    (user_profile.nivel.match(/Super/i) && (!situacao_chamado.titulo.match(/solucionado/i))) ||
                    (tech.id === user_profile.id && (qtd_tec > 1) && (!situacao_chamado.titulo.match(/fechado|solucionado|encerrado_inatividade/i)))) &&
                    <div key={`${tech.id}`}
                        onMouseOver={(e) => { e.target.style.opacity = 1 }}
                        onMouseLeave={(e) => { e.target.style.opacity = 0 }}
                        onClick={async () => {
                            await removeRespTec(navigation_state.data._id, tech)
                        }}

                        style={{ cursor: 'pointer', opacity: 0, backgroundColor: '#00000055', color: '#fff', position: 'absolute', width: '96%', textAlign: 'center' }}>
                        X
                    </div>
                }
                {tech.nome}
            </div>))
            :
            props.dados.map(tech => (<div style={{ padding: 2, borderRadius: 3, border: '1px grey solid', marginLeft: 5, marginTop: -2, fontSize: '1em', position: 'relative' }}>
                {(
                    (user_profile.nivel.match(/Super/i) && (!situacao_chamado.titulo.match(/solucionado/i))) ||
                    (tech.id === user_profile.id && (qtd_tec > 1) && (!situacao_chamado.titulo.match(/fechado|solucionado|encerrado_inatividade/i)))) &&
                    <div
                        onMouseOver={(e) => { e.target.style.opacity = 1 }}
                        onMouseLeave={(e) => { e.target.style.opacity = 0 }}
                        onClick={async () => {
                            await removeRespTec(navigation_state.data._id, tech)
                        }}
                        style={{ cursor: 'pointer', opacity: 0, backgroundColor: '#00000055', color: '#fff', position: 'absolute', width: '90%', textAlign: 'center' }}>
                        X
                    </div>
                }
                {tech.nome.split(' ')[0]}
            </div>))

        if (user_profile.nivel.match(/Super|Técnico-Admin/i)) {
            return (
                <>
                    TÉCNICO(S): {(!situacao_chamado.titulo.match(/solucionado/i)) &&
                        <span
                            style={{ cursor: 'pointer', marginLeft: 5, marginRight: 5, fontSize: '1.4em' }}
                            className="material-icons"
                            onClick={() => {
                                if (navigation_state.data.categoria == '') {
                                    set_show_modal_categorizacao(true)
                                    return
                                } else {
                                    show_toast_users()
                                }
                            }}>person_add_alt</span>} {tecnicos.map(tecnico => (tecnico))}
                </>
            )
        } else if (user_profile.nivel === 'Técnico') {
            let show_add = true

            if (navigation_state.data.resposaveis_tecnicos.find(el => (el.id === user_profile.id ? true : false))) {
                show_add = false
            }

            if (show_add && (!situacao_chamado.titulo.match(/fechado|solucionado|encerrado_inatividade/i))) {
                return (
                    <>
                        TÉCNICO(S): <span
                            style={{ cursor: 'pointer', marginLeft: 5, marginRight: 5, fontSize: '1.4em' }}
                            className="material-icons"
                            onClick={() => {
                                if (navigation_state.data.categoria == '') {
                                    set_show_modal_categorizacao(true)
                                    return
                                } else {
                                    addRespTec(navigation_state?.data._id, user_profile)
                                }
                            }}
                        >person_add_alt</span> {tecnicos.map(tecnico => (tecnico))}
                    </>
                )
            } else {
                return (<>TÉCNICO(S): {tecnicos.length > 0 ? tecnicos.map(tecnico => (tecnico)) : 'Nenhum técnico atribuído a este chamado'}</>)
            }

        } else if (user_profile.nivel.match(/Cliente/) || (situacao_chamado.titulo === 'fechado')) {
            return (<>TÉCNICO(S): {tecnicos.length > 0 ? tecnicos.map(tecnico => (tecnico)) : 'Nenhum técnico atribuído a este chamado'}</>)
        }
    }

    const getListUsers = async () => {
        const users = await api_bd.get('/users')
        const alphabetically = users.data.filter(user => user.ativo === true).sort((a, b) => a.nome.localeCompare(b.nome))

        let usersList = [...alphabetically]

        for (let ukey in usersList) {
            let avataruser = await api_bd.get(`/users/getavatar/${usersList[ukey].id}`)
            usersList[ukey]['image_profile'] = avataruser.data.image_profile
            // console.log(avataruser.data)
        }

        set_lista_usuarios(usersList)
    }


    const start_treatment = async () => {
        const dados_ini = {
            id_usuario: user_profile.id,
            nivel: user_profile.nivel,
            nome: user_profile.nome,
            inicio: Date.now()
        }

        const resp = await api_bd.put(`/tickets/start_treatment/${navigation_state.data._id}`, dados_ini)
        set_navigation_state({ page_content: 'ticket_detail', data: resp.data[0] })
        set_situacao_chamado(resp.data[0].situacao)
    }

    const stop_treatment = async () => {
        const dados_fim = {
            id_usuario: user_profile.id,
            nivel: user_profile.nivel,
            nome: user_profile.nome,
            fim: Date.now()
        }

        const resp = await api_bd.put(`/tickets/stop_treatment/${navigation_state.data._id}`, dados_fim)
        set_navigation_state({ page_content: 'ticket_detail', data: resp.data[0] })
        set_situacao_chamado(resp.data[0].situacao)
        set_tratativa_aberta(null)
    }


    const msToTime = (ms) => {
        let x = ms / 1000
        let secs = x % 60
        x /= 60
        let mins = x % 60
        x /= 60
        let hrs = x % 24
        x /= 24
        let days = x

        // console.log(days)

        // return Math.floor(days)-1 + 'd:'  + hrs<10 ? '0'+hrs : hrs + ':' + mins + ':' + secs
        return (`${days >= 1 ? Math.floor(days) + ' dia(s) ' : ''}${hrs < 10 ? '0' + Math.floor(hrs) : Math.floor(hrs)}:${mins < 10 ? '0' + Math.floor(mins) : Math.floor(mins)}:${secs < 10 ? '0' + Math.floor(secs) : Math.floor(secs)}`)
    }

    const calcMs = (_time_inicio, _time_fim) => {
        const date1 = Date.parse(_time_inicio)
        const date2 = Date.parse(_time_fim)
        const diffTime = date2 - date1
        return (diffTime)
    }


    const FormatTratativas = (props) => {

        let t_execucao = 0
        for (let key in props.dados) {
            if (props.dados[key].fim !== null) {
                const tempo_decorrido = calcMs(props.dados[key].inicio, props.dados[key].fim)
                t_execucao += tempo_decorrido

                // console.log('tempo:', msToTime(tempo_decorrido))
                // console.log('Tempo Total:', msToTime(t_execucao))
                // console.log('\n')
            }

        }
        // console.log('FIM\n\n\n')

        const tratativa_atual = props.dados.filter(tratativa => {
            // console.log(tratativa)
            if (tratativa.id_usuario === user_profile.id && tratativa.fim === null) {
                return tratativa
            } else {
                return false
            }
        })


        let diffTime = t_execucao

        set_tempo_de_execucao(diffTime)
        if (tratativa_atual.length > 0) {
            set_tratativa_aberta(tratativa_atual[0].inicio)

            // console.log('Tempo total atual: ', tempo_de_execucao)
            // console.log('tratativa_aberta: ', tratativa_aberta)
            // console.log('iso String', new Date().toISOString())

            // cronometroInterval = setInterval(() => {
            //     const isoString = new Date().toISOString()
            //     const tempo = calcMs(tratativa_aberta, isoString)
            //     set_tempo_de_execucao((tempo_de_execucao + tempo))
            // }, 5000)
        }

        return (
            <>
                <div style={{ fontSize: '1em', fontWeight: 'bold', color: 'grey' }}>
                    {props.dados.length === 0 ? 'TEMPO DE EXECUÇÃO: 00:00:00' : ''}
                    {props.dados.length > 0 && tratativa_atual.length === 0 && tempo_de_execucao !== null &&
                        `TEMPO DE EXECUÇÃO: ${msToTime(tempo_de_execucao)}`
                        // `TEMPO DE EXECUÇÃO: ${msToTime(diffTime)}`
                    }
                    {tratativa_atual.length > 0 &&
                        <div style={{ fontSize: '1em', display: 'flex', flexDirection: 'row' }}>
                            TRATATIVA INICIADA EM {formatDate(tratativa_aberta)}
                        </div>
                    }
                </div>
                <div style={{ position: 'relative' }}>
                    {(
                        (user_profile.nivel.match(/Super/i) && (!situacao_chamado.titulo.match(/(novo|solucionado)/i)) && (meu_chamado) && (tratativa_atual.length === 0)) ||
                        (user_profile.nivel.match(/Técnico/i) && (!situacao_chamado.titulo.match(/(novo|fechado|encerrado_inatividade|solucionado)/i)) && (meu_chamado) && (tratativa_atual.length === 0))
                    ) &&
                        <span
                            style={{ marginLeft: 5, fontSize: '1.5em', marginTop: -2, cursor: 'pointer' }}
                            className="material-icons"
                            onClick={() => {
                                return tratativa_aberta === null ? start_treatment() : alert('Você já possui um chamado em atendimento, é preciso finalizar o mesmo antes de iniciar um novo atendimento.')
                            }}
                        >play_circle</span>
                    }
                    {(user_profile.nivel.match(/Super/i) || user_profile.nivel.match(/Técnico/i)) && tratativa_atual.length > 0 &&
                        <>
                            <div className="miniloader" style={{ position: 'absolute', width: 18, height: 18, marginLeft: 5, marginTop: -3, zIndex: -1 }}></div>
                            <span
                                style={{ position: 'relative', marginLeft: 5, fontSize: '1.5em', marginTop: -3, cursor: 'pointer', zIndex: 250 }}
                                className="material-icons"
                                onClick={() => {
                                    stop_treatment()
                                }}
                            >
                                stop_circle
                            </span>

                        </>

                    }
                </div>
            </>
        )
    }


    useEffect(() => {
        (async () => {
            // const resp = await api_bd('/tickets')
            // set_lista_chamados(resp.data)
            await getListUsers()
        })()
        // const dw = document.documentElement.clientWidth
        const dh = document.documentElement.clientHeight
        // set_device_width(dw)
        set_device_height(dh)
        // console.log(navigation_state)
    }, [])

    useEffect(() => {
        if (Object.keys(navigation_state).length > 0) {
            set_meu_chamado(navigation_state.data.resposaveis_tecnicos.find(el => el.id === user_profile.id) ? true : false)
            set_qtd_tecnicos(navigation_state.data.resposaveis_tecnicos.length)
        }
    }, [navigation_state])


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            flex: 1,
            left: 0,
            top: 0,
            maxWidth: '100%',
            justifyContent: 'center',
            alignItems: 'flex-start',
            overflowY: "auto"
        }}
            className="height_less_header">
            {show_toast &&
                <Toast _style={{ backgroundColor: '#3c5ca6cc', color: 'white' }}  >
                    {content_toast}
                </Toast>
            }

            {false && //teste nova notificação
                <Toast _style={{ backgroundColor: '#3c5ca6cc', color: 'white' }} >
                    <h2>Teste notificação</h2>
                </Toast>
            }

            {show_modal_categorizacao &&
                <Modal callbackfn={() => {
                    set_content_toast(<div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <h2><b>NOTIFICAÇÃO!</b></h2>
                        <h2 style={{ marginTop: 10 }}>Necessário definir categoria para se atribuir ao chamado!</h2>
                    </div>)
                    setTimeout(() => { set_show_toast(true) }, 500)
                    setTimeout(() => { set_show_toast(false) }, 7000)
                    set_show_modal_categorizacao(false)
                }}>
                    <TicketPopup handle_close={() => {
                        set_show_toast(false)
                        set_show_modal_categorizacao(false)
                        set_content_toast(null)

                        if (user_profile.nivel.match(/Super/i)) {
                            show_toast_users()
                        } else if (user_profile.nivel.match(/Técnico/i)) {
                            addRespTec(navigation_state?.data._id, user_profile)
                        }

                    }} ticket_id={navigation_state.data._id} />
                </Modal>
            }

            {show_modal_pendencia &&
                <Modal callbackfn={() => modal_close()}>
                    <div className={styles.div_root_component} style={{ backgroundColor: '#fff', borderRadius: 10, width: '70%' }}>
                        <TextField
                            style={{ margin: 50, width: '85%' }}
                            className={styles.form_input}
                            multiline
                            onChange={e => {
                                if (e.target.value.length > 200) { return false }
                                set_motivo_da_situacao(e.target.value)
                            }}
                            rows={8}
                            label="Motivo da Situaçao"
                            inputProps={{ placeholder: "Digite o motivo da situação", autoFocus: true }}
                            value={motivo_da_situacao}
                        />
                    </div>
                </Modal>
            }


            {show_form_agendamento_janela &&
                <Modal callbackfn={() => set_show_form_agendamento_janela(false)}>
                    <Form_Agendamento />
                </Modal>
            }


            {options_modal.show &&
                <Modal
                    _style={{ alignItems: 'center', height: '85vh' }}
                    btn_close_position={{ position: 'absolute', top: '30%', marginLeft: '68%' }}
                    callbackfn={() => {
                        set_options_modal((currentState) => ({ ...currentState, content: null, show: false }))
                        if (show_toast) {
                            set_show_toast(false)
                        }
                    }}>
                    {options_modal.content}
                </Modal>
            }


            <div className={styles.div_root_component} style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingLeft: 2, paddingRight: 2 }}>

                {navigation_state?.data && Object.keys(navigation_state.data).length > 0 &&
                    <>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                            <div
                                style={{ width: '100%', fontSize: '1.5em', fontWeight: 'bold', marginTop: 20, display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                                onClick={() => set_navigation_state({ page_content: 'listtickets' })}
                            >
                                <span className="material-icons" style={{ marginLeft: 0, fontSize: '1em' }}>arrow_back_ios</span><div style={{ marginTop: -2, fontSize: '1.1em' }}>MEUS CHAMADOS</div>
                            </div>


                            <div style={{ maxWidth: '49%', minWidth: '49%', width: '49%', fontSize: '1em', fontWeight: 'bold', color: 'grey', marginTop: 20 }}>
                                CHAMADO ID: {navigation_state?.data.cod_id < 10 ? '000' + navigation_state?.data.cod_id : navigation_state?.data.cod_id < 100 ? '00' + navigation_state?.data.cod_id : navigation_state?.data.cod_id < 1000 ? '0' + navigation_state?.data.cod_id : navigation_state?.data.cod_id}
                            </div>


                            <div style={{ display: 'flex', maxWidth: '49%', minWidth: '49%', width: '49%', fontSize: '1em', fontWeight: 'bold', color: 'grey', marginTop: 20 }}>
                                PRIORIDADE: {/* navigation_state?.data.prioridade */}
                                <div style={{ marginTop: -3, paddingLeft: 5 }}>
                                    <span
                                        style={{ margin: 2, fontSize: '1.3em', cursor: user_profile.nivel.match(/super|técnico-admin/i) ? 'pointer' : '', color: navigation_state?.data.prioridade === 'padrão' ? "#888888" : navigation_state?.data.prioridade === 'baixa' ? "#228B22" : navigation_state?.data.prioridade === 'média' ? "#ff7900" : "#ff0000" }}
                                        className="material-icons"
                                        onClick={async () => {
                                            if (user_profile.nivel.match(/super|técnico-admin/i)) {
                                                if (window.confirm('Deseja alterar a prioridade deste chamado?')) {
                                                    let response = await api_bd.put(`/tickets/${navigation_state?.data.id}`, { prioridade: 'baixa' })
                                                    set_navigation_state({
                                                        page_content: 'ticket_detail',
                                                        data: { ...response.data[0] }
                                                    })
                                                }
                                            }
                                        }}
                                    >warning</span>

                                    <span style={{ margin: 2, fontSize: '1.3em', cursor: user_profile.nivel.match(/super|técnico-admin/i) ? 'pointer' : '', color: navigation_state?.data.prioridade === 'média' ? "#ff7900" : navigation_state?.data.prioridade === 'alta' ? "#ff0000" : "#888888" }}
                                        className="material-icons"
                                        onClick={async () => {
                                            if (user_profile.nivel.match(/super|técnico-admin/i)) {
                                                if (window.confirm('Deseja alterar a prioridade deste chamado?')) {
                                                    let response = await api_bd.put(`/tickets/${navigation_state?.data.id}`, { prioridade: 'média' })
                                                    set_navigation_state({
                                                        page_content: 'ticket_detail',
                                                        data: { ...response.data[0] }
                                                    })
                                                }
                                            }
                                        }}
                                    >warning</span>

                                    <span
                                        style={{ margin: 2, fontSize: '1.3em', cursor: user_profile.nivel.match(/super|técnico-admin/i) ? 'pointer' : '', color: navigation_state?.data.prioridade === 'alta' ? "#ff0000" : "#888888" }}
                                        className="material-icons"
                                        onClick={async () => {
                                            if (user_profile.nivel.match(/super|técnico-admin/i)) {
                                                if (window.confirm('Deseja alterar a prioridade deste chamado?')) {
                                                    let response = await api_bd.put(`/tickets/${navigation_state?.data.id}`, { prioridade: 'alta' })
                                                    set_navigation_state({
                                                        page_content: 'ticket_detail',
                                                        data: { ...response.data[0] }
                                                    })
                                                }
                                            }
                                        }}
                                    >warning</span>
                                </div>
                            </div>


                            <div style={{ maxWidth: '49%', minWidth: '49%', width: '49%', fontSize: '1em', fontWeight: 'bold', color: 'grey', marginTop: 15 }}>
                                TIPO DE SOLICITAÇÃO: {tipos_de_solicitacao[navigation_state?.data.tipo_solicitacao] ? tipos_de_solicitacao[navigation_state?.data.tipo_solicitacao].toUpperCase() : 'NÃO DEFINIDA'}
                            </div>


                            <div style={{ maxWidth: '49%', minWidth: '49%', width: '49%', fontSize: '1em', fontWeight: 'bold', color: 'grey', marginTop: 15 }}>
                                CATEGORIA: {categorias[navigation_state?.data.categoria] ? categorias[navigation_state?.data.categoria].toUpperCase() : 'NÃO DEFINIDA'} {navigation_state?.data?.subcategoria ? ` / ${subcategorias[navigation_state?.data.subcategoria]}` : ''}
                            </div>


                            <div style={{ maxWidth: '49%', minWidth: '49%', width: '49%', fontSize: '1em', fontWeight: 'bold', color: 'grey', marginTop: 15 }}>
                                REQUERENTE: {navigation_state?.data.nome} ({navigation_state?.data.customer_name})
                            </div>



                            {navigation_state?.data &&
                                <div style={{ maxWidth: '49%', minWidth: '49%', width: '49%', fontSize: '1em', fontWeight: 'bold', color: 'grey', marginTop: 15, display: 'flex', flexDirection: 'row' }}>
                                    <FormatRespTec dados={navigation_state?.data?.resposaveis_tecnicos} id_ticket={navigation_state?.data?._id} />
                                </div>
                            }


                            {navigation_state?.data?.tratativas &&
                                <div style={{ maxWidth: '49%', minWidth: '49%', width: '49%', marginTop: 15, display: 'flex', flexDirection: 'row' }}>
                                    <FormatTratativas dados={navigation_state?.data?.tratativas} />
                                </div>
                            }


                            <div style={{ maxWidth: '49%', minWidth: '49%', width: '49%', fontSize: '1em', fontWeight: 'bold', color: 'grey', marginTop: 9 }}>
                                {(
                                    (user_profile.nivel.match(/Cliente/)) ||
                                    (user_profile.nivel.match(/Técnico/i) && situacao_chamado.titulo === 'fechado') ||
                                    (user_profile.nivel.match(/Técnico/i) && meu_chamado === false)) && <div style={{ marginTop: 5, marginBottom: 8 }}>SITUAÇÃO: {tipos_situacao[navigation_state?.data.situacao.titulo].toUpperCase()} </div>}
                                {(
                                    (user_profile.nivel.match(/Super/i)) ||
                                    ((user_profile.nivel.match(/Técnico/i)) && (meu_chamado) && (situacao_chamado.titulo !== 'fechado'))
                                ) &&
                                    <>
                                        {((situacao_chamado, user_profile, qtd_tecnicos, motivo_da_situacao, navigation_state, set_show_form_agendamento_janela, set_motivo_da_situacao, set_situacao_chamado, set_show_modal_pendencia, set_navigation_state) => {
                                            let options = []
                                            for (let k in tipos_situacao) {
                                                options.push(<MenuItem value={`${k}`} style={{ color: ((user_profile.nivel.match(/Técnico/i) && (k.match(/novo|atribuido|em_andamento|pendente_inec|fechado/i))) || (k.match(/solucionado|pendente_inec|follow_up/i))) ? 'lightgray' : '' }}>{tipos_situacao[k]}</MenuItem>)
                                            }

                                            return (
                                                <>
                                                    SITUAÇÃO: <Select
                                                        style={{ marginLeft: 5, width: 200, marginBottom: -30 }}
                                                        value={situacao_chamado.titulo}
                                                        defaultValue={situacao_chamado.titulo}
                                                        labelId="select-label-situacao-do-servico"
                                                        onChange={async (e) => {
                                                            if ((user_profile.nivel === 'Técnico') && (situacao_chamado.titulo.match(/Aguardando aprovação|Aguardando auditoria/))) { return false } //impede alteração pelo técnico nessas situações
                                                            // if(user_profile.nivel.match(/Técnico/i) && (e.target.value.match(/(novo|atribuido|fechado|solucionado)/i))){return false}
                                                            if (user_profile.nivel.match(/Técnico/i) && (!e.target.value.match(/(aguardando|encerrado_inatividade|cancelado|janela_agendada|em_andamento|Aguardando aprovação||pendente_outros|pendente_cliente|pendente_fornecedor|em_tratativa|em_execucao)/i))) { return false }
                                                            if (user_profile.nivel.match(/Super/i) && e.target.value.match(/solucionado|em_andamento|pendente_inec|follow_up/i)) { return false }
                                                            if (qtd_tecnicos === 0 && e.target.value.match(/(solucionado|encerrado_inatividade|fechado|follow_up)/i)) { return false }


                                                            const dt_followup = new Date()
                                                            dt_followup.setDate(dt_followup.getDate() + 3)
                                                            const next_date_to_open = `${dt_followup.getFullYear()}-${dt_followup.getMonth() < 10 ? '0' : ''}${dt_followup.getMonth() + 1}-${dt_followup.getDate()}`


                                                            const { ...tkdata } = navigation_state.data

                                                            if (e.target.value === 'janela_agendada') {
                                                                set_show_form_agendamento_janela(true)
                                                            }


                                                            if (e.target.value === 'pendente_outros' || e.target.value === 'cancelado') {
                                                                set_motivo_da_situacao('')
                                                                set_situacao_chamado({ titulo: e.target.value, descricao: motivo_da_situacao })
                                                                set_show_modal_pendencia(true)

                                                            } else if ((e.target.value.match(/pendente_/i)) && (e.target.value !== 'pendente_outros')) {
                                                                set_situacao_chamado({ titulo: e.target.value, descricao: '' })

                                                                tkdata.situacao = { titulo: e.target.value, descricao: '' }
                                                                tkdata.followup = { state: true, reopen_date: dt_followup }

                                                                const resp_data = await api_bd.put(`/tickets/${tkdata._id}`, tkdata)
                                                                set_navigation_state({ page_content: 'ticket_detail', data: resp_data.data[0] })

                                                            } else {
                                                                set_situacao_chamado({ titulo: e.target.value, descricao: '' })

                                                                tkdata.situacao = { titulo: e.target.value, descricao: '' }
                                                                tkdata.followup = { state: false, reopen_date: '' }

                                                                const resp_data = await api_bd.put(`/tickets/${tkdata._id}`, tkdata)
                                                                set_navigation_state({ page_content: 'ticket_detail', data: resp_data.data[0] })
                                                            }
                                                        }}
                                                    >
                                                        {options}
                                                        {/* <MenuItem value="novo" style={{ color: user_profile.nivel.match(/Técnico/i) ? 'lightgray' : '' }}>Novo</MenuItem>
                                                        <MenuItem value="atribuido" style={{ color: user_profile.nivel.match(/Técnico/i) ? 'lightgray' : '' }}>Atribuído</MenuItem>

                                                        <MenuItem value="aguardando_agendamento">Aguardando Agendamento</MenuItem>
                                                        <MenuItem value="janela_agendada">Janela Agendada</MenuItem>

                                                        <MenuItem value="em_andamento" style={{ color: user_profile.nivel.match(/Técnico/i) ? 'lightgray' : '' }}>Em andamento</MenuItem>

                                                        <MenuItem value="pendente_inec">Pendente Intec</MenuItem>
                                                        <MenuItem value="pendente_cliente">Pendente Cliente</MenuItem>
                                                        <MenuItem value="pendente_fornecedor">Pendente Fornecedor</MenuItem>
                                                        <MenuItem value="pendente_outros">Pendente Outros</MenuItem>

                                                        <MenuItem value="solucionado" style={{ color: 'lightgray' }}>Solucionado</MenuItem>
                                                        <MenuItem value="fechado" style={{ color: user_profile.nivel.match(/Técnico/i) ? 'lightgray' : '' }}>Fechado</MenuItem>

                                                        <MenuItem value="encerrado_inatividade">Encerrado por inatividade</MenuItem>
                                                        <MenuItem value="cancelado">Cancelado</MenuItem>
                                                        <MenuItem value="follow_up">Follow-UP</MenuItem> */}
                                                    </Select>

                                                </>
                                            )
                                        })(situacao_chamado, user_profile, qtd_tecnicos, motivo_da_situacao, navigation_state, set_show_form_agendamento_janela, set_motivo_da_situacao, set_situacao_chamado, set_show_modal_pendencia, set_navigation_state)}


                                    </>
                                }
                            </div>

                            <div style={{ maxWidth: '49%', minWidth: '49%', width: '49%', fontSize: '1em', fontWeight: 'bold', color: 'grey', marginTop: 15 }}>
                                DATA DE ABERTURA: {formatDate(navigation_state?.data.createdAt)}
                            </div>

                            {(navigation_state?.data.situacao?.titulo === 'pendente_outros' || navigation_state?.data.situacao?.titulo === 'cancelado') && navigation_state?.data.situacao?.descricao &&
                                <div style={{ maxWidth: '49%', minWidth: '49%', width: '49%', fontSize: '1em', fontWeight: 'bold', color: 'grey', marginTop: 15 }}>
                                    MOTIVO DA SITUAÇÃO: {navigation_state?.data.situacao?.descricao}
                                </div>
                            }

                            {user_profile.nivel.match(/Técnico|Super/i) &&
                                <div style={{ maxWidth: '49%', minWidth: '49%', width: '49%', marginTop: 15, display: 'flex', flexDirection: 'row' }}>
                                    <Button
                                        style={{ marginRight: 20 }}
                                        startIcon={<span style={{ marginLeft: -15 }} className="material-icons">dashboard</span>}
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={async () => {
                                            //window.open(`https://sgi.intecsolutions.com.br/clientdetails/${navigation_state.data.customer_id}`)
                                            window.open(`http://${window.location.host}/clientdetails/${navigation_state.data.customer_id}`)
                                        }}
                                    >Acessos</Button>

                                    <Button
                                        // className={styles.btn_submit}
                                        startIcon={<span style={{ marginLeft: -15 }} className="material-icons">pending_actions</span>}
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={async () => {
                                            const dt_followup2 = new Date()
                                            dt_followup2.setDate(dt_followup2.getDate() + 3)
                                            const next_date_to_open = `${dt_followup2.getFullYear()}-${dt_followup2.getMonth() < 10 ? '0' : ''}${dt_followup2.getMonth() + 1}-${dt_followup2.getDate()}`

                                            const { ...tkdata2 } = navigation_state.data

                                            tkdata2.situacao = { titulo: 'follow_up', descricao: '' }
                                            tkdata2.followup = { state: true, reopen_date: dt_followup2 }

                                            const content = <div className={`modal_40_70 ${styles.div_root_component}`} style={{ backgroundColor: '#fff', borderRadius: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <TextField
                                                    style={{ margin: 15, width: '90%' }}
                                                    className={styles.form_input}
                                                    onChange={e => {
                                                        console.log('DATA new followup', e.target.value)
                                                        tkdata2.followup = { state: true, reopen_date: new Date(`${e.target.value} 00:01:00`) }
                                                    }}
                                                    type="date"
                                                    defaultValue={next_date_to_open}
                                                    label="Data para reabertura (padrão 3 dias)"
                                                    inputProps={{ placeholder: "" }}
                                                // value={}
                                                />

                                                <Button
                                                    className={styles.btn_submit}
                                                    variant="contained"
                                                    color="primary"
                                                    // size="small"
                                                    disabled={show_toast}
                                                    style={{ width: '70%' }}
                                                    onClick={async () => {
                                                        console.log('tkdata2 click btn:', tkdata2)
                                                        const resp_data = await api_bd.put(`/tickets/${tkdata2._id}`, tkdata2)
                                                        set_situacao_chamado({ titulo: 'follow_up', descricao: '' })
                                                        set_navigation_state({ page_content: 'ticket_detail', data: resp_data.data[0] })
                                                        set_options_modal((currentState) => ({ ...currentState, show: false }))
                                                    }}
                                                >
                                                    Salvar
                                                </Button>
                                            </div>
                                            set_options_modal((currentState) => ({ ...currentState, content, show: true }))
                                            return false
                                        }}
                                    >Follow-UP</Button>
                                </div>
                            }

                        </div>



                        {/* FLOW NODES */}
                        {navigation_state?.data.categoria.match(/Implantação/i) &&
                            <FlowNodes items={[]} idTicket={navigation_state?.data.id} loggeduser={user_profile} set_situacao_chamado={set_situacao_chamado} />
                        }



                        <div style={{ marginTop: 30, minWidth: '70%', maxWidth: '70%', width: '70%', borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: 'hidden', alignSelf: 'flex-start' }}>
                            <div
                                style={{ backgroundColor: primary_color, padding: 15, color: 'white', width: '100%', cursor: user_profile.nivel.match(/super|técnico-admin/i) ? 'pointer' : '' }}
                                onClick={async () => {
                                    if (user_profile.nivel.match(/super|técnico-admin/i)) {
                                        if (window.confirm('Deseja alterar o titulo deste chamado?')) {
                                            let newTitle = window.prompt('Digite o novo titulo.')
                                            if (newTitle) {
                                                let response = await api_bd.put(`/tickets/${navigation_state?.data.id}`, { titulo: newTitle })
                                                set_navigation_state({
                                                    page_content: 'ticket_detail',
                                                    data: { ...response.data[0] }
                                                })
                                            } else {
                                                window.alert('Compo de título vazio, o chamado não foi alterado')
                                            }
                                        }
                                    }
                                }}
                            >
                                {navigation_state?.data.titulo}
                            </div>
                            <div style={{ backgroundColor: '#ececec', padding: 15, color: '#000', width: '100%', minHeight: 80, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, }}>
                                {navigation_state?.data &&
                                    <Descricao dados={navigation_state.data} labels={data_labels} />
                                }
                                {navigation_state?.data?.anexo?.length > 0 &&
                                    <div style={{ marginTop: 5, padding: 10, textAlign: 'left' }}>
                                        <span style={{ marginLeft: 0, fontSize: '0.9em' }}><b>ANEXO(S)</b></span>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', marginTop: 10, textAlign: 'left', fontSize: '0.8em', color: 'grey', width: '100%', borderBottomLeftRadius: 10, borderBottomRightRadius: 10, }}>
                                            {navigation_state.data.anexo.map((anexo => (
                                                <a href={`${backend_ticket_files}/${anexo.filename}`} target="_blank">
                                                    <div style={{ width: '15vw', minWidth: '15vw', maxWidth: '15vw', marginRight: 10 }}>
                                                        {anexo.filename.match(/\.jpeg|\.jpg|\.png|\.gif|\.pjpeg|\.svg/i)
                                                            ?
                                                            <img style={{ width: '100%' }} src={`${backend_ticket_files}/${anexo.filename}`} />
                                                            : anexo.filename.match(/\.aac|\.m4a|\.mp3/i)
                                                                ?
                                                                <>
                                                                    {anexo.filename.split('-')[1]}
                                                                    <audio controls>
                                                                        <source src={`${backend_ticket_files}/${anexo.filename}`} />
                                                                    </audio>
                                                                </>
                                                                : anexo.filename.match(/\.mp4|\.ogg|\.3gp|\.mpeg/i)
                                                                    ?
                                                                    <>
                                                                        {anexo.filename.split('-')[1]}
                                                                        <video width="320" height="240" controls>
                                                                            <source src="movie.mp4" />
                                                                        </video>
                                                                    </>
                                                                    : anexo.filename.match(/\.pdf/i)
                                                                        ?
                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}><span style={{ fontSize: '2.5em' }} className="material-icons">picture_as_pdf</span> {anexo.filename.split('-')[1]}</div>
                                                                        : anexo.filename.match(/\.xls|\.xlsx|\.csv|\.doc|\.docx|\.txt/i)
                                                                            ?
                                                                            <div style={{ display: 'flex', flexDirection: 'row' }}><span style={{ fontSize: '2.5em' }} className="material-icons">description</span> {anexo.filename.split('-')[1]}</div>
                                                                            :
                                                                            `${anexo.filename.split('-')[1]}`
                                                        }
                                                    </div>

                                                </a>
                                            )))}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        {navigation_state?.data.interacoes.map((interacao) => (
                            ((interacao.privado && user_profile.nivel.match(/técnico|super/i)) || !interacao.privado) &&
                            <div style={{ backgroundColor: (interacao.finalizado && interacao.finalizado === true) ? '#90ee90' : interacao.privado ? '#00009088' : interacao.nivel.match(/Cliente/) ? '#ececec' : '#c7c7c7', color: '#000', marginTop: 30, minWidth: '70%', maxWidth: '70%', width: '70%', borderRadius: 10, overflow: 'hidden', alignSelf: interacao.nivel.match(/Cliente/) ? 'flex-start' : 'flex-end' }}>
                                {interacao.privado &&
                                    <div style={{ fontSize: '0.9em', color: '#fff', paddingLeft: 15, paddingTop: 15 }}><b>COMENTÁRIO PRIVADO</b></div>
                                }

                                {interacao.finalizado &&
                                    <div style={{ fontSize: '0.9em', color: 'grey', paddingLeft: 15, paddingTop: 15 }}><b>SOLUÇÃO</b></div>
                                }

                                {interacao.texto &&
                                    <div style={{ width: '100%', minHeight: 50, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, }}>
                                        <pre style={{ whiteSpace: 'pre-wrap', padding: 15 }}>{interacao.texto}</pre>
                                    </div>
                                }
                                <div style={{ textAlign: 'right', fontSize: '0.8em', color: interacao.privado ? '#ffffff' : 'grey', padding: 15, width: '100%', borderBottomLeftRadius: 10, borderBottomRightRadius: 10, }}>
                                    {interacao?.anexo?.length > 0 &&
                                        <div style={{ marginTop: 5, padding: 10, textAlign: 'left' }}>
                                            <span style={{ marginLeft: 0, fontSize: '0.9em' }}><b>ANEXO(S)</b></span>
                                            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', marginTop: 10, textAlign: 'left', fontSize: '0.8em', color: 'grey', width: '100%', borderBottomLeftRadius: 10, borderBottomRightRadius: 10, }}>
                                                {interacao.anexo.map((anexo => (
                                                    <a href={`${backend_ticket_files}/${anexo.filename}`} target="_blank">
                                                        <div style={{ width: '15vw', minWidth: '15vw', maxWidth: '15vw', marginRight: 10 }}>
                                                            {anexo.filename.match(/\.jpeg|\.jpg|\.png|\.gif|\.pjpeg|\.svg/i)
                                                                ?
                                                                <img style={{ width: '100%', minHeight: '10%' }} src={`${backend_ticket_files}/${anexo.filename}`} />
                                                                : anexo.filename.match(/\.aac|\.m4a|\.mp3/i)
                                                                    ?
                                                                    <>
                                                                        {anexo.filename.split('-')[1]}
                                                                        <audio controls>
                                                                            <source src={`${backend_ticket_files}/${anexo.filename}`} />
                                                                        </audio>
                                                                    </>
                                                                    : anexo.filename.match(/\.mp4|\.ogg|\.3gp|\.mpeg/i)
                                                                        ?
                                                                        <>
                                                                            {anexo.filename.split('-')[1]}
                                                                            <video width="320" height="240" controls>
                                                                                <source src={`${backend_ticket_files}/${anexo.filename}`} />
                                                                            </video>
                                                                        </>
                                                                        : anexo.filename.match(/\.pdf/i)
                                                                            ?
                                                                            <div style={{ display: 'flex', flexDirection: 'row' }}><span style={{ fontSize: '2.5em' }} className="material-icons">picture_as_pdf</span> {anexo.filename.split('-')[1]}</div>
                                                                            : anexo.filename.match(/\.xls|\.xlsx|\.csv|\.doc|\.docx|\.txt/i)
                                                                                ?
                                                                                <div style={{ display: 'flex', flexDirection: 'row' }}><span style={{ fontSize: '2.5em' }} className="material-icons">description</span> {anexo.filename.split('-')[1]}</div>
                                                                                :
                                                                                `${anexo.filename.split('-')[1]}`
                                                            }
                                                        </div>

                                                    </a>
                                                )))}
                                            </div>
                                        </div>
                                    }
                                    {interacao.nome} em {formatDate(interacao.criadoem)}
                                </div>
                            </div>
                        ))}

                        {/* <div style={{ marginTop: 30, minWidth: '70%', maxWidth: '70%', width: '70%', borderRadius: 10, overflow: 'hidden', alignSelf: 'flex-start' }}>
                            <div style={{ backgroundColor: '#ececec', padding: 10, color: '#000', width: '100%', minHeight: 100, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, }}>
                                Interação Cliente
                            </div>
                        </div> */}

                        {(
                            (user_profile.nivel.match(/Cliente/) && (show_input_text === false) && (situacao_chamado.titulo !== 'fechado')) ||
                            (user_profile.nivel.match(/Técnico/i) && (show_input_text === false) && (situacao_chamado.titulo.match(/atribuido|pendente|em_tratativa|em_execucao|janela_agendada|follow_up|encerrado_inatividade|cancelado/i))) ||
                            (user_profile.nivel.match(/Super/i) && (show_input_text === false))
                        ) &&
                            <>
                                <div style={{ display: 'flex', border: '2px #c7c7c7 dashed', marginTop: 30, minWidth: '70%', maxWidth: '70%', width: '70%', height: 100, borderRadius: 10, overflow: 'hidden', alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
                                    <span onClick={() => { set_show_input_text(true) }} style={{ cursor: 'pointer' }}><b>+ NOVA INTERAÇÃO</b></span>
                                </div>
                            </>
                        }

                        {show_input_text &&
                            <>
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30, minWidth: '70%', maxWidth: '70%', width: '70%', minHeight: 110, overflow: 'hidden', alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>

                                    <TextField
                                        inputProps={{ autoFocus: true, placeholder: 'Digite aqui.', style: { marginTop: user_profile.nivel.match(/Cliente/) ? 0 : 0 } }}
                                        style={{ width: '100%' }} rows={10}
                                        multiline variant="outlined"
                                        onChange={(e) => { set_text_interacao(e.target.value) }}
                                    />
                                </div>
                                <div style={{ display: 'flex', border: '2px #c7c7c7 solid', marginTop: 2, minWidth: '70%', maxWidth: '70%', width: '70%', minHeight: 50, borderRadius: 3, overflow: 'hidden', alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
                                    {/* <FileUploaderMulti set_files={(e) => {
                                        console.log(e)
                                    }} /> */}
                                    <FileDropzone set_files={set_anexo_interacao} />
                                </div>

                                {/* Alterar por um botão */}
                                <div style={{ display: 'flex', marginTop: 2, minWidth: '70%', maxWidth: '70%', width: '70%', height: 50, borderRadius: 3, overflow: 'hidden', alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
                                    <Button
                                        className={styles.btn_submit}
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        disabled={show_toast}
                                        style={{ width: '100%' }}
                                        onClick={async () => {

                                            console.log((anexo_interacao && anexo_interacao.length == 0) && text_interacao == '')
                                            if ((anexo_interacao && anexo_interacao.length == 0) && text_interacao == '') {
                                                set_content_toast(() => (
                                                    <div className="flex" style={{ justifyContent: 'center', alignItems: 'center', width: '100%', maxWidth: '100%', height: 70, maxHeight: 70 }}>
                                                        <span style={{ fontSize: '1.2em' }}>Texto ou anexo deve ser preenchido!</span>
                                                    </div>
                                                ))
                                                set_show_toast(true)
                                                setTimeout(() => {
                                                    set_show_toast(false)
                                                }, 5000)
                                                return
                                            }

                                            set_content_toast(() => (
                                                <div className="flex" style={{ justifyContent: 'center', alignItems: 'center', width: '100%', maxWidth: '100%', height: 70, maxHeight: 70 }}>
                                                    <div className="loader" style={{ width: 40, height: 40, marginRight: 10 }}></div>
                                                    <h3>Aguarde, enviando dados...</h3>
                                                </div>
                                            ))
                                            set_show_toast(true)
                                            const dados = {
                                                id_usuario: user_profile.id,
                                                nivel: user_profile.nivel,
                                                nome: user_profile.nome,
                                                texto: text_interacao,
                                                finalizado: solucao_ticket,
                                                privado: false
                                            }

                                            if (solucao_ticket) {
                                                stop_treatment() //para o relogio se uma solução for inserida
                                            }


                                            if (anexo_interacao && anexo_interacao.length > 0) {

                                                if (user_profile.nivel.match(/Técnico|Super/i)) {
                                                    const content = <div className={`${styles.div_root_component} modal_40_95 `} style={{ backgroundColor: '#fff', borderRadius: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <div style={{ marginTop: 30, fontSize: '1em', fontWeight: 'bold' }}>
                                                            SELECIONE O TIPO DA INTERAÇÃO:

                                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                                <Button
                                                                    className={styles.btn_submit}
                                                                    variant="contained"
                                                                    // size="small"
                                                                    disabled={show_toast}
                                                                    style={{ width: 150, height: 70, backgroundColor: '#c7c7c7', color: '#000', marginRight: 5 }}
                                                                    onClick={async () => {
                                                                        dados.privado = false
                                                                        dados.finalizado = false

                                                                        const data_FormData = new FormData();

                                                                        for (let a in anexo_interacao) {
                                                                            // console.log(anexo_interacao[a])
                                                                            data_FormData.append('file', anexo_interacao[a])
                                                                        }

                                                                        for (let key in dados) {
                                                                            data_FormData.append(key, dados[key])
                                                                        }

                                                                        const resp = await api_bd.put(`/tickets/addinteract/${navigation_state.data._id}`, data_FormData, {
                                                                            headers: {
                                                                                "Content-Type": `multipart/form-data; boundary=${data_FormData._boundary}`,
                                                                            }
                                                                        })
                                                                        set_show_input_text(false)
                                                                        set_text_interacao('')
                                                                        set_navigation_state({
                                                                            page_content: 'ticket_detail',
                                                                            data: { ...resp.data[0] }
                                                                        })
                                                                        set_situacao_chamado(resp.data[0].situacao)

                                                                        set_show_toast(false)
                                                                        set_options_modal((currentState) => ({ ...currentState, content: null, show: false }))

                                                                    }}>
                                                                    Interação com o cliente
                                                                </Button>


                                                                <Button
                                                                    className={styles.btn_submit}
                                                                    variant="contained"
                                                                    // size="small"
                                                                    disabled={show_toast}
                                                                    style={{ minWidth: 150, width: 150, height: 70, backgroundColor: '#00009088', color: '#fff' }}
                                                                    onClick={async () => {
                                                                        dados.privado = true

                                                                        const data_FormData = new FormData();

                                                                        for (let a in anexo_interacao) {
                                                                            // console.log(anexo_interacao[a])
                                                                            data_FormData.append('file', anexo_interacao[a])
                                                                        }

                                                                        for (let key in dados) {
                                                                            data_FormData.append(key, dados[key])
                                                                        }

                                                                        const resp = await api_bd.put(`/tickets/addinteract/${navigation_state.data._id}`, data_FormData, {
                                                                            headers: {
                                                                                "Content-Type": `multipart/form-data; boundary=${data_FormData._boundary}`,
                                                                            }
                                                                        })
                                                                        set_show_input_text(false)
                                                                        set_text_interacao('')
                                                                        set_navigation_state({
                                                                            page_content: 'ticket_detail',
                                                                            data: { ...resp.data[0] }
                                                                        })
                                                                        set_situacao_chamado(resp.data[0].situacao)

                                                                        set_show_toast(false)
                                                                        set_options_modal((currentState) => ({ ...currentState, content: null, show: false }))

                                                                    }}>
                                                                    Comentário Privado
                                                                </Button>


                                                                <Button
                                                                    className={styles.btn_submit}
                                                                    variant="contained"
                                                                    // size="small"
                                                                    disabled={show_toast}
                                                                    style={{ width: 150, height: 70, backgroundColor: '#90ee90', color: '#000', marginLeft: 5 }}
                                                                    onClick={async () => {
                                                                        dados.finalizado = true
                                                                        await stop_treatment() //para o relogio se uma solução for inserida

                                                                        const data_FormData = new FormData();

                                                                        for (let a in anexo_interacao) {
                                                                            // console.log(anexo_interacao[a])
                                                                            data_FormData.append('file', anexo_interacao[a])
                                                                        }

                                                                        for (let key in dados) {
                                                                            data_FormData.append(key, dados[key])
                                                                        }

                                                                        const resp = await api_bd.put(`/tickets/addinteract/${navigation_state.data._id}`, data_FormData, {
                                                                            headers: {
                                                                                "Content-Type": `multipart/form-data; boundary=${data_FormData._boundary}`,
                                                                            }
                                                                        })
                                                                        set_show_input_text(false)
                                                                        set_text_interacao('')
                                                                        set_navigation_state({
                                                                            page_content: 'ticket_detail',
                                                                            data: { ...resp.data[0] }
                                                                        })
                                                                        set_situacao_chamado(resp.data[0].situacao)

                                                                        set_show_toast(false)
                                                                        set_options_modal((currentState) => ({ ...currentState, content: null, show: false }))

                                                                    }}>
                                                                    Solução do chamado
                                                                </Button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    set_options_modal((currentState) => ({ ...currentState, content, show: true }))

                                                } else {
                                                    const data_FormData = new FormData();

                                                    for (let a in anexo_interacao) {
                                                        // console.log(anexo_interacao[a])
                                                        data_FormData.append('file', anexo_interacao[a])
                                                    }

                                                    for (let key in dados) {
                                                        data_FormData.append(key, dados[key])
                                                    }

                                                    let resp = await api_bd.put(`/tickets/addinteract/${navigation_state.data._id}`, data_FormData, {
                                                        headers: {
                                                            "Content-Type": `multipart/form-data; boundary=${data_FormData._boundary}`,
                                                        }
                                                    })
                                                    set_show_input_text(false)
                                                    set_text_interacao('')
                                                    set_navigation_state({
                                                        page_content: 'ticket_detail',
                                                        data: { ...resp.data[0] }
                                                    })
                                                    set_situacao_chamado(resp.data[0].situacao)
                                                    set_show_toast(false)
                                                }
                                            } else {
                                                if (user_profile.nivel.match(/Técnico|Super/i)) {
                                                    const content = <div className={`${styles.div_root_component} modal_40_95`} style={{ backgroundColor: '#fff', borderRadius: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <div style={{ width: '80%', marginTop: 30, marginBottom: 30, fontSize: '1em', fontWeight: 'bold' }}>
                                                            SELECIONE O TIPO DA INTERAÇÃO:

                                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                <Button
                                                                    className={styles.btn_submit}
                                                                    variant="contained"
                                                                    // size="small"
                                                                    disabled={show_toast}
                                                                    style={{ width: 150, height: 70, backgroundColor: '#c7c7c7', color: '#000', marginRight: 5 }}
                                                                    onClick={async () => {
                                                                        dados.privado = false
                                                                        dados.finalizado = false

                                                                        const resp = await api_bd.put(`/tickets/addinteract/${navigation_state.data._id}`, dados)

                                                                        set_show_input_text(false)
                                                                        set_text_interacao('')
                                                                        set_navigation_state({
                                                                            page_content: 'ticket_detail',
                                                                            data: { ...resp.data[0] }
                                                                        })
                                                                        set_situacao_chamado(resp.data[0].situacao)

                                                                        set_show_toast(false)
                                                                        set_options_modal((currentState) => ({ ...currentState, content: null, show: false }))

                                                                    }}>
                                                                    Interação com o cliente
                                                                </Button>


                                                                <Button
                                                                    className={styles.btn_submit}
                                                                    variant="contained"
                                                                    // size="small"
                                                                    disabled={show_toast}
                                                                    style={{ width: 150, height: 70, backgroundColor: '#00009088', color: '#fff' }}
                                                                    onClick={async () => {
                                                                        dados.privado = true

                                                                        const resp = await api_bd.put(`/tickets/addinteract/${navigation_state.data._id}`, dados)

                                                                        set_show_input_text(false)
                                                                        set_text_interacao('')
                                                                        set_navigation_state({
                                                                            page_content: 'ticket_detail',
                                                                            data: { ...resp.data[0] }
                                                                        })
                                                                        set_situacao_chamado(resp.data[0].situacao)

                                                                        set_show_toast(false)
                                                                        set_options_modal((currentState) => ({ ...currentState, content: null, show: false }))

                                                                    }}>
                                                                    Comentário Privado
                                                                </Button>


                                                                <Button
                                                                    className={styles.btn_submit}
                                                                    variant="contained"
                                                                    // size="small"
                                                                    disabled={show_toast}
                                                                    style={{ width: 150, height: 70, backgroundColor: '#90ee90', color: '#000', marginLeft: 5 }}
                                                                    onClick={async () => {
                                                                        dados.finalizado = true
                                                                        await stop_treatment() //para o relogio se uma solução for inserida

                                                                        const resp = await api_bd.put(`/tickets/addinteract/${navigation_state.data._id}`, dados)

                                                                        set_show_input_text(false)
                                                                        set_text_interacao('')
                                                                        set_navigation_state({
                                                                            page_content: 'ticket_detail',
                                                                            data: { ...resp.data[0] }
                                                                        })
                                                                        set_situacao_chamado(resp.data[0].situacao)

                                                                        set_show_toast(false)
                                                                        set_options_modal((currentState) => ({ ...currentState, content: null, show: false }))

                                                                    }}>
                                                                    Solução do chamado
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    set_options_modal((currentState) => ({ ...currentState, content, show: true }))

                                                } else {
                                                    let resp = await api_bd.put(`/tickets/addinteract/${navigation_state.data._id}`, dados)

                                                    set_show_input_text(false)
                                                    set_text_interacao('')
                                                    set_navigation_state({
                                                        page_content: 'ticket_detail',
                                                        data: { ...resp.data[0] }
                                                    })
                                                    set_situacao_chamado(resp.data[0].situacao)

                                                    set_show_toast(false)
                                                }

                                            }

                                            // set_show_input_text(false)
                                            // set_text_interacao('')
                                            // set_navigation_state({
                                            //     page_content: 'ticket_detail',
                                            //     data: { ...resp.data[0] }
                                            // })
                                            // set_situacao_chamado(resp.data[0].situacao)
                                        }}>
                                        Salvar
                                    </Button>
                                </div>

                            </>
                        }
                    </>
                }
            </div>
        </div >
    );
}

export default TicketDetail;