
import React, { useState, useEffect } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil'

import userState, { profileData } from '../../atoms/user.atom'

// import { Doughnut, Bar } from 'react-chartjs-2';
import Chart from "react-google-charts";


import { api_bd } from '../../services/Api';


function DashBoard1() {


    const [dados, setDados] = useState({})

    const [dadosGraficos, setDadosGraficos] = useState({})


    const shuffle = function (array) {

        var currentIndex = array.length;
        var temporaryValue, randomIndex;

        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    };

    const generateColor = function () {
        var hex = ['a', 'b', 'c', 'd', 'e', 'f', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        var color = '#';
        for (var i = 0; i < 6; i++) {
            shuffle(hex);
            color += hex[0];
        }
        return color;

    };

    const msToTime = (ms) => {

        var hours = ms / (1000 * 60 * 60);
        var absoluteHours = Math.floor(hours);
        var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

        //Get remainder from hours and convert to minutes
        var minutes = (hours - absoluteHours) * 60;
        var absoluteMinutes = Math.floor(minutes);
        var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

        //Get remainder from minutes and convert to seconds
        var seconds = (minutes - absoluteMinutes) * 60;
        var absoluteSeconds = Math.floor(seconds);
        var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;


        return h + ':' + m + ':' + s;

    }

    const profile_user = useRecoilValue(profileData)


    const options = {
        plugins: {
            datalabels: {
                display: true,
                align: 'bottom',
                backgroundColor: '#ccc',
                borderRadius: 3,
                font: {
                    size: 18,
                }
            },
        }
    }



    useEffect(() => {
        (async () => {
            const response = await api_bd.get(`/relatorios/state/todos`)
            const clientes = response.data.clientes

            setDados(response.data)
        })()
    }, [])



    useEffect(() => {
        if (dados.clientes) {
            let clientes = dados.clientes

            let tmpDadosGraficos = {
                ticksTempoTotalPorCliente: [],
                tempoTotalPorCliente: [['Cliente', 'Tempo']],
                chamadosAbertoNoMesPorCliente: [],
                chamadosAntigosPorCliente: []
            }

            for (let key in clientes) {
                let nome = key
                tmpDadosGraficos.tempoTotalPorCliente.push([nome, { v: clientes[key].tempoTotal_ms, f: `${msToTime(clientes[key].tempoTotal_ms)}hs` }])

                tmpDadosGraficos.ticksTempoTotalPorCliente.push({
                    v: clientes[key].tempoTotal_ms,
                    f: msToTime(clientes[key].tempoTotal_ms) + 'hs'
                });


                tmpDadosGraficos.chamadosAbertoNoMesPorCliente.push([nome, { v: clientes[key].abertosNoMes, f: `${clientes[key].abertosNoMes}` }])
                tmpDadosGraficos.chamadosAntigosPorCliente.push([nome, { v: clientes[key].antigosTratadosNoMes, f: `${clientes[key].antigosTratadosNoMes}` }])
            }

            setDadosGraficos(tmpDadosGraficos)
        }

    }, [dados])



    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
            {Object.keys(dadosGraficos).length === 0 &&
                <div style={{ display: 'flex', width: '100%', minHeight: '60vh', justifyContent: 'center', alignItems: 'center', fontSize: '2em', fontWeight: 'bolder' }}>
                    Carregando Dados....
                </div>
            }

            {Object.keys(dadosGraficos).length > 0 &&
                <>
                    <div style={{ width: '50%', maxHidth: '50%', order: 1 }}>
                        <Chart
                            // width={'500px'}
                            height={'300px'}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={dadosGraficos.chamadosAbertoNoMesPorCliente}
                            options={{
                                title: 'Qtd de chamados abertos no mês',
                            }}
                            rootProps={{ 'data-testid': '1' }}
                        />
                    </div>


                    <div style={{ width: '50%', maxHidth: '50%', order: 2 }}>
                        <Chart
                            // width={'500px'}
                            height={'300px'}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={dadosGraficos.chamadosAntigosPorCliente}
                            options={{
                                title: 'Qtd de chamados Antigos tratados no mês',
                            }}
                            rootProps={{ 'data-testid': '1' }}
                        />
                    </div>


                    <div style={{ width: '100%', order: 3 }}>
                        <Chart
                            // width={400}
                            height={500}
                            chartType="ColumnChart"
                            loader={<div>Loading Chart</div>}
                            data={dadosGraficos.tempoTotalPorCliente}
                            options={{
                                title: 'Tempo Total por cliente',
                                chartArea: { width: '80%' },
                                hAxis: {
                                    title: 'Cliente',

                                },
                                vAxis: {
                                    title: 'Tempo',
                                    minValue: 0,
                                    ticks: dadosGraficos.ticksTempoTotalPorCliente,
                                    format: 'none'
                                },
                            }}

                        />
                    </div>
                </>
            }
        </div>
    );
}

export default DashBoard1;
