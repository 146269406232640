import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './pages/Login';
import Logout from './pages/Logout';
// import Home from './pages/Home';
import CadUser from './pages/CadUser';
import CadClient from './pages/CadClient';
import ClientDetails from './pages/ClientDetails';
// import NavigateTopSeacrh from './pages/NavigateTopSearch';
import NetworkMap from './pages/NetworkMap';
import Suport_Calendar from './pages/Suport_Calendar';
import PageTickets from './pages/PageTickets';
import RoutePrivate from './components/RoutePrivate';

import RelatorioImplantacao from './components/FlowNodesComponent/Relatorio';


import ProjectsPage from './pages/Projects';


import Home_N from './pages/NewHome';


// import LeftSideBar from './components/SideBars/LeftSideBar'

import Stations from './pages/Stations';

import { useRecoilValue } from 'recoil'
import userState, { profileData } from './atoms/user.atom'

// import { getLoginToken, setLoginToken } from './services/Api'

function App(props) {
	// const user = useRecoilValue(userState) || {}
	const profile_data = useRecoilValue(profileData) || {}

	// const [token, setToken] = useState(undefined)

	// useEffect(() => {
	// 	// (async () => {
	// 	// 	const session_token = await getLoginToken()		
	// 	// 	setToken(session_token)
	// 	// })()
	// 	console.log('profileData')
	//     console.log(profile_data)
	// }, [])


	// if(Object.keys(profile_data).length === 0){
	// 	if(window.location.pathname !=='/'){
	// 		window.location.href='/'
	// 	}
	// 	return <Login />
	// }

	// if (!token) {
	// 	if(window.location.pathname != '/'){
	// 		window.location.href='/'
	// 	}

	// 	// console.log(token)
	// 	return <Login setToken={setToken} />
	// }


	return (
		<Router>
			{Object.keys(profile_data).length > 0 ? <Navbar /> : <></>}
			<Switch>
				{/* <Route path='/' exact component={Home} /> */}
				{/* <Route path='/' exact component={LeftSideBar} /> */}
				<Route path='/login' exact component={Login} />
				<RoutePrivate path='/' exact component={Home_N} />
				<RoutePrivate path='/ch/:idticket' exact component={Home_N} />
				<RoutePrivate path='/caduser' component={CadUser} />
				<RoutePrivate path='/cadclient' component={CadClient} />
				<RoutePrivate path='/clientdetails' component={ClientDetails} />
				<RoutePrivate path='/stations' component={Stations} />
				<RoutePrivate path='/tickets' component={PageTickets} />
				<RoutePrivate path='/suport' component={Suport_Calendar} />
				{/* <RoutePrivate path='/logout' render={(props => <Logout />)} /> */}
				<RoutePrivate path='/networkmap' component={NetworkMap} />
				<RoutePrivate path='/projects' component={ProjectsPage} />
				<RoutePrivate path='/logout' component={Logout} />
				<RoutePrivate path='/relatorio-i' component={RelatorioImplantacao} />
				<RoutePrivate path='*' component={Home_N} />
				{/* <RoutePrivate path='/nav' render={(props => <NavigateTopSeacrh {...props} />)} /> */}
			</Switch>
		</Router>
	);
}

export default App;



