import React, { useState, useEffect } from 'react';

import Modal from '../Modal'

import Button from '@material-ui/core/Button';

import TriagemTicket from './Triagem';
import PlanejamentoEtapas from './PlanejamentoEtapas';
import ConteudoEtapas from './ConteudoEtapas';
import AprovacaoGestor from './AprovacaoGestor';
import AuditoriaGestor from './AuditoriaGestor';


import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import navigationState from '../../atoms/navigate_state.atom';

import Styles, { primary_color } from '../Styles';

import { api_bd } from '../../services/Api';
import { keys } from '@material-ui/core/styles/createBreakpoints';

const FlowNodes = (props) => {
    const styles = Styles();
    const [showModal, setShowModal] = useState(false)
    const [contentModal, setContentModal] = useState(<></>)

    const [navigation_state, set_navigation_state] = useRecoilState(navigationState)

    let itemToRender = []
    // const arrSteps = navigation_state.data.flowNodes.validateSteps.state === 'aproved' ? navigation_state.data.flowNodes.steps : {}
    const actualState = navigation_state.data.flowNodes.actualState - 1 //props.actualState - 1

    var itemsToList

    if (navigation_state.data.flowNodes.validateSteps.state === 'aproved' && navigation_state.data.flowNodes.validateExecutionSteps.state === 'aproved') {
        itemsToList = [
            { title: 'Triagem' },
            {
                title: 'Planejamento de etapas',
                title2: 'Planejamento da execução'
            },
            {
                title: 'Aprovação do planejamento pelo gestor',
                title2: 'Aprovação da execução pelo gestor'

            },
            ...navigation_state.data.flowNodes.steps.filter(step => step.type === 'Planejamento'),
            ...navigation_state.data.flowNodes.steps.filter(step => step.type === 'Agendamento de Janela'),
            ...navigation_state.data.flowNodes.steps.filter(step => step.type === 'Levantamento de Insumos'),
            ...navigation_state.data.flowNodes.steps.filter(step => step.type === 'Execução'),
            // ...navigation_state.data.flowNodes.steps,
            { title: 'Auditoria pelo gestor do setor' },
            { title: 'Atividade concluída' }
        ]
    } else {
        itemsToList = [
            { title: 'Triagem' },
            {
                title: 'Planejamento de etapas',
                title2: 'Planejamento da execução'
            },
            {
                title: 'Aprovação do planejamento pelo gestor',
                title2: 'Aprovação da execução pelo gestor'

            },
            { title: 'Auditoria pelo gestor do setor' },
            { title: 'Atividade concluída' }
        ]
    }

    const animation_bounce = { className: 'animation_filter_active' }



    // const listContents = [
    //     <TriagemTicket idTicket={props.idTicket} callbackfn={() => {
    //         setShowModal(false)
    //     }} setContentModal={setContentModal} />,

    //     <PlanejamentoEtapas set_situacao_chamado={props.set_situacao_chamado} idTicket={props.idTicket} callbackfn={() => {
    //         setShowModal(false)
    //     }} />,

    //     <AprovacaoGestor set_situacao_chamado={props.set_situacao_chamado} idTicket={props.idTicket} callbackfn={() => {
    //         setShowModal(false)
    //     }} />

    // ]

    const getBackgroundColor = (tipoEtapa) => {
        switch (tipoEtapa.toUpperCase()) {
            case 'PLANEJAMENTO':
                return 'green'
                break;

            case 'LEVANTAMENTO DE INSUMOS':
                return 'green'
                break;

            case 'EXECUÇÃO':
                return 'green'
                break;

            case 'AGENDAMENTO DE JANELA':
                return 'green'
                break;

            default:
                return 'green'
                break;
        }
    }



    const CountCheck = (props) => {
        const countCheck = props.checkItems.filter(item => item.state === true).length
        const percent = (countCheck / props.checkItems.length) * 100
        return (<span style={{ fontSize: '0.9em', marginLeft: 0, color: primary_color }}>{percent}%</span>)
    }


    for (let i = 0; i < itemsToList.length; i++) {
        if (i === 0) {
            itemToRender.push(<div>{/* Primeiro nó */}
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <div name='1'
                        className={actualState === i ? 'animation_filter_active' : ''}
                        onClick={() => {
                            // if (listContents[i] && props.loggeduser.nivel.match(/Técnico|Super/)) {
                            if (props.loggeduser.nivel.match(/Técnico|Super/)) {
                                // setContentModal(listContents[i])
                                setContentModal(<TriagemTicket idTicket={props.idTicket} callbackfn={() => {
                                    setShowModal(false)
                                }} setContentModal={setContentModal} />)

                                setShowModal(true)
                            }
                        }}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: 23,
                            width: 50,
                            minWidth: 50,
                            height: 50,
                            borderRadius: 25,
                            backgroundColor: actualState >= i ? primary_color : 'gray',
                            zIndex: 10,
                            cursor: props.loggeduser.nivel.match(/Técnico|Super/) ? 'pointer' : ''
                        }}>
                        {actualState >= i &&
                            <div style={{ width: 30, minWidth: 30, height: 30, borderRadius: 15, backgroundColor: '#fff' }}></div>
                        }
                    </div>
                    <div name='2' style={{ display: 'flex', minWidth: 30, height: 10, backgroundColor: actualState > i ? primary_color : 'gray', marginLeft: -3, zIndex: 1 }}></div>
                </div>
                <div name='1' style={{ marginTop: 5, textAlign: 'center', maxWidth: 90 }}>{itemsToList[i].title}</div>
            </div>)

        } else if (i === 1) {
            itemToRender.push(<div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <div name='2' style={{ minWidth: 25, height: 10, backgroundColor: actualState >= i ? primary_color : 'gray', marginRight: -3, zIndex: 1 }}></div>
                    <div name='1'
                        className={actualState === i ? 'animation_filter_active' : ''}
                        onClick={() => {
                            if (props.loggeduser.nivel.match(/Técnico|Super/)) {
                                // if (listContents[i]) {
                                // setContentModal(listContents[i])
                                setContentModal(<PlanejamentoEtapas set_situacao_chamado={() => {
                                    // props.set_situacao_chamado
                                    console.log('opaa')
                                }} idTicket={props.idTicket} callbackfn={() => {
                                    setShowModal(false)
                                }} />)

                                setShowModal(true)
                                // }   
                            }
                        }}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 50, minWidth: 50, height: 50, borderRadius: 25, backgroundColor: actualState >= i ? primary_color : 'gray', zIndex: 10, cursor: (actualState >= i && props.loggeduser.nivel.match(/Técnico|Super/)) ? 'pointer' : '' }}>
                        {actualState >= i &&
                            <div style={{ width: 30, minWidth: 30, height: 30, borderRadius: 15, backgroundColor: '#fff' }}></div>
                        }
                    </div>
                    <div name='2' style={{ minWidth: 25, height: 10, backgroundColor: actualState > i ? primary_color : 'gray', marginLeft: -3, zIndex: 1 }}></div>
                </div>
                <div name='1' style={{ marginTop: 5, textAlign: 'center', maxWidth: 90 }}>
                    {
                        // itemsToList[i].title
                    }
                    {navigation_state.data.flowNodes.validateSteps.state !== 'aproved' && itemsToList[i].title}
                    {navigation_state.data.flowNodes.validateSteps.state === 'aproved' && itemsToList[i].title2}
                </div>
            </div>)

        } else if (i === 2) {
            itemToRender.push(<div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <div name='2' style={{ minWidth: 25, height: 10, backgroundColor: actualState >= i ? primary_color : 'gray', marginRight: -3, zIndex: 1 }}></div>
                    <div name='1'
                        className={actualState === i ? 'animation_filter_active' : ''}
                        onClick={() => {
                            if (props.loggeduser.nivel.match(/Técnico|Super/)) {
                                if (props.loggeduser.nivel.match(/Super|Técnico-Admin/)) {
                                    // if (listContents[i]) {
                                    // setContentModal(listContents[i])
                                    setContentModal(<AprovacaoGestor set_situacao_chamado={props.set_situacao_chamado} setShowModal={setShowModal} idTicket={props.idTicket} loggeduser={props.loggeduser} callbackfn={() => {
                                        setShowModal(false)
                                    }} />)
                                    setShowModal(true)
                                    // }
                                }
                            }
                        }}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 50, minWidth: 50, height: 50, borderRadius: 25, backgroundColor: actualState >= i ? primary_color : 'gray', zIndex: 10, cursor: (actualState >= i && props.loggeduser.nivel.match(/Técnico|Super/)) ? 'pointer' : '' }}>
                        {actualState >= i &&
                            <div style={{ width: 30, minWidth: 30, height: 30, borderRadius: 15, backgroundColor: '#fff' }}></div>
                        }
                    </div>
                    <div name='2' style={{ minWidth: 25, height: 10, backgroundColor: actualState > i ? primary_color : 'gray', marginLeft: -3, zIndex: 1 }}></div>
                </div>
                <div name='1' style={{ marginTop: 5, textAlign: 'center', maxWidth: 90 }}>
                    {
                        // itemsToList[i].title
                    }
                    {navigation_state.data.flowNodes.validateSteps.state !== 'aproved' && itemsToList[i].title}
                    {navigation_state.data.flowNodes.validateSteps.state === 'aproved' && itemsToList[i].title2}
                </div>
            </div>)

        } else if ((i > 2) && (i < (itemsToList.length - 2))) {
            //Nós feitos pelos técnicos
            itemToRender.push(<div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <div name='2' style={{ minWidth: 25, height: 10, backgroundColor: actualState >= i ? primary_color : 'gray', marginRight: -3, zIndex: 1 }}></div>
                    <div name='1'
                        className={actualState === i ? 'animation_filter_active' : ''}
                        onClick={() => {
                            if (actualState >= i && props.loggeduser.nivel.match(/Técnico|Super/)) {
                                // setContentModal(listContents[i])
                                // setShowModal(true)
                                setContentModal(
                                    <ConteudoEtapas
                                        etapaAtual={(i + 1)}
                                        set_situacao_chamado={props.set_situacao_chamado}
                                        loggeduser={props.loggeduser}
                                        idTicket={props.idTicket}
                                        setContentModal={setContentModal}
                                        setShowModal={setShowModal}
                                        itemsToList={itemsToList}
                                    />
                                )
                                setShowModal(true)
                            }
                        }}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 50,
                            minWidth: 50,
                            height: 50,
                            borderRadius: 25,
                            backgroundColor: actualState >= i ? getBackgroundColor(itemsToList[i].type) : 'gray',
                            zIndex: 10,
                            cursor: (actualState >= i && props.loggeduser.nivel.match(/Técnico|Super/)) ? 'pointer' : ''
                        }}>
                        {actualState >= i &&
                            <div style={{ width: 30, minWidth: 30, height: 30, borderRadius: 15, backgroundColor: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {itemsToList[i].type.toUpperCase() === 'LEVANTAMENTO DE INSUMOS' &&
                                    <CountCheck checkItems={itemsToList[i].list.items} />
                                }
                            </div>
                        }
                    </div>
                    <div name='2' style={{ minWidth: 25, height: 10, backgroundColor: actualState > i ? primary_color : 'gray', marginLeft: -3, zIndex: 1 }}></div>
                </div>
                <div name='1' style={{ marginTop: 5, textAlign: 'center', maxWidth: 90, color: itemsToList[i].state === 'incomplete' ? 'red' : '' }}>
                    {itemsToList[i].title}<br /><p style={{ fontSize: '0.7em', fontWeight: 'bolder' }}>({itemsToList[i].type.toUpperCase()})</p>
                </div>
            </div>)

        } else if (i === itemsToList.length - 1) {
            itemToRender.push(<div>{/* ultimo nó */}
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <div name='2' style={{ minWidth: 30, height: 10, backgroundColor: actualState === i ? primary_color : 'gray', marginRight: -3, zIndex: 1 }}></div>
                    <div name='1'
                        className={actualState === i ? 'animation_filter_active' : ''}
                        onClick={() => {
                            if (actualState >= i) {
                                window.open('/relatorio-i')
                                // window.alert('Finalizado')
                                // setContentModal(<RelatorioImplantacao />)
                                // setShowModal(true)
                            }
                        }}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 23, width: 50, minWidth: 50, height: 50, borderRadius: 25, backgroundColor: actualState >= i ? primary_color : 'gray', zIndex: 10, cursor: actualState >= i ? 'pointer' : '' }}>
                        {actualState >= i &&
                            <div style={{ width: 30, minWidth: 30, height: 30, borderRadius: 15, backgroundColor: '#fff' }}></div>
                        }
                    </div>
                </div>
                <div name='1' style={{ marginTop: 5, textAlign: 'center', maxWidth: 90 }}>{itemsToList[i].title}</div>
            </div>)

        } else if (i == (itemsToList.length - 2)) {
            itemToRender.push(<div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <div name='2' style={{ minWidth: 25, height: 10, backgroundColor: actualState >= i ? primary_color : 'gray', marginRight: -3, zIndex: 1 }}></div>
                    <div name='1'
                        className={actualState === i ? 'animation_filter_active' : ''}
                        onClick={() => {
                            if (actualState >= i && props.loggeduser.nivel.match(/Técnico|Super/)) {
                                if ((i == (itemsToList.length - 2)) && !props.loggeduser.nivel.match(/Super|Técnico-Admin/)) {
                                    window.alert('Apenas um administrador pode realizar a auditoria.')
                                } else {
                                    // setContentModal(<ConteudoAuditoriaGestor set_situacao_chamado={props.set_situacao_chamado} allFlowNodes={itemsToList} idTicket={props.idTicket} loggeduser={props.loggeduser} pontuation={navigation_state.data?.flowNodes?.validateAudit?.pontuation || []} />)
                                    setContentModal(<AuditoriaGestor set_situacao_chamado={props.set_situacao_chamado} setShowModal={setShowModal} allFlowNodes={itemsToList} idTicket={props.idTicket} loggeduser={props.loggeduser} />)
                                    setShowModal(true)
                                }
                            }
                        }}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 50, minWidth: 50, height: 50, borderRadius: 25, backgroundColor: actualState >= i ? primary_color : 'gray', zIndex: 10, cursor: (actualState >= i && props.loggeduser.nivel.match(/Técnico|Super/)) ? 'pointer' : '' }}>
                        {actualState >= i &&
                            <div style={{ width: 30, minWidth: 30, height: 30, borderRadius: 15, backgroundColor: '#fff' }}></div>
                        }
                    </div>
                    <div name='2' style={{ minWidth: 25, height: 10, backgroundColor: actualState > i ? primary_color : 'gray', marginLeft: -3, zIndex: 1 }}></div>
                </div>
                <div name='1' style={{ marginTop: 5, textAlign: 'center', maxWidth: 90 }}>{itemsToList[i].title}</div>
            </div>)
        }
        // else {
        //     itemToRender.push(<div>
        //         <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        //             <div name='2' style={{ minWidth: 25, height: 10, backgroundColor: actualState >= i ? primary_color : 'gray', marginRight: -3, zIndex: 1 }}></div>
        //             <div name='1'
        //                 className={actualState === i ? 'animation_filter_active' : ''}
        //                 onClick={() => {
        //                     if (actualState >= i && props.loggeduser.nivel.match(/Técnico|Super/)) {
        //                         if (i === 2 && props.loggeduser.nivel.match(/Super|Técnico-Admin/)) {
        //                             if (listContents[i]) {
        //                                 // setContentModal(listContents[i])
        //                                 setContentModal(<AprovacaoGestor set_situacao_chamado={props.set_situacao_chamado} setShowModal={setShowModal} allFlowNodes={itemsToList} idTicket={props.idTicket} loggeduser={props.loggeduser} />)
        //                                 setShowModal(true)
        //                             }
        //                         } if (i === 2 && !props.loggeduser.nivel.match(/Super|Técnico-Admin/)) {
        //                             window.alert('Apenas um administrador pode aprovar as etapas de planejamento')
        //                         } else {
        //                             if (listContents[i]) {
        //                                 // setContentModal(listContents[i])
        //                                 setShowModal(true)
        //                             }
        //                         }
        //                     }
        //                 }}
        //                 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 50, minWidth: 50, height: 50, borderRadius: 25, backgroundColor: actualState >= i ? primary_color : 'gray', zIndex: 10, cursor: (actualState >= i && props.loggeduser.nivel.match(/Técnico|Super/)) ? 'pointer' : '' }}>
        //                 {actualState >= i &&
        //                     <div style={{ width: 30, minWidth: 30, height: 30, borderRadius: 15, backgroundColor: '#fff' }}></div>
        //                 }
        //             </div>
        //             <div name='2' style={{ minWidth: 25, height: 10, backgroundColor: actualState > i ? primary_color : 'gray', marginLeft: -3, zIndex: 1 }}></div>
        //         </div>
        //         <div name='1' style={{ marginTop: 5, textAlign: 'center', maxWidth: 90 }}>{itemsToList[i].title}</div>
        //     </div>)
        // }
    }



    return (
        <>
            {showModal &&
                <Modal callbackfn={() => {
                    setShowModal(false)
                }}>
                    <div className='modal_40_70'>
                        {contentModal}
                    </div>
                </Modal>
            }
            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderTop: '#c3c3c3 solid 1px', borderBottom: '#c3c3c3 solid 1px', marginTop: 30, marginBottom: 30 }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', flexWrap: 'wrap', marginTop: 40, marginBottom: 30, minWidth: '80%', maxWidth: '80%', width: '80%' }}>
                    {itemToRender}
                </div>
            </div>
        </>
    )
}

export default FlowNodes;