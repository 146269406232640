import React, { useState, useEffect } from 'react';

import Modal from '../Modal'

import Button from '@material-ui/core/Button';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import navigationState from '../../atoms/navigate_state.atom';

import Styles, { primary_color } from '../Styles';

import { api_bd } from '../../services/Api';

import CheckBoxesInsumos from './CheckBoxesInsumos';

import FormDataCalendar from '../page_contents/FormDataCalendar'

const ConteudoEtapas = (props) => {

    const styles = Styles();

    const [tmpStateStep, setTempStateStep] = useState({})

    const [navigation_state, set_navigation_state] = useRecoilState(navigationState)

    const actualItem = props.itemsToList[props.etapaAtual - 1]

    useEffect(() => {
        console.log(tmpStateStep)
        // console.log(props.etapaAtual - 1)
        console.log(navigation_state.data?.flowNodes?.steps[props.etapaAtual - 4])
        if (navigation_state.data?.flowNodes?.steps[props.etapaAtual - 4]) {
            const { state, ...tempStep } = navigation_state.data.flowNodes.steps[props.etapaAtual - 4]

            setTempStateStep(tempStep)
        }
    }, [])

    return (
        <div style={{ width: '100%', backgroundColor: '#fff', borderRadius: 5, padding: 50 }}>
            <div style={{ fontSize: '1.3em', fontWeight: 'bold', marginBottom: 30 }}>Deseja Concluir a etapa: {props.etapaAtual} ({props.itemsToList[props.etapaAtual - 1].title})</div>


            {actualItem.type.toUpperCase() === 'LEVANTAMENTO DE INSUMOS' &&
                <CheckBoxesInsumos navigate_step_index={(props.etapaAtual - 4)} callbackfn={() => { props.setShowModal(false) }} />
            }



            {/* {actualItem.type.toUpperCase() === 'AGENDAMENTO DE JANELA' &&
                <FormDataCalendar eventType="janela de manutenção" cod_id={navigation_state.data.cod_id} navigate_step_index={(props.etapaAtual - 4)} callbackfn={() => { props.setShowModal(false) }} />
            } */}




            <div id='div_opts1' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                <Button
                    className={styles.btn_submit}
                    variant="contained"
                    size="small"
                    style={{ width: 200, height: 40, backgroundColor: primary_color, color: '#fff', marginRight: 5 }}
                    onClick={async () => {
                        window.document.getElementById('div_opts2').style.display = 'block'
                        window.document.getElementById('div_opts1').style.display = 'none'
                    }}>
                    SIM / PULAR ETAPA
                </Button>

                <Button
                    className={styles.btn_submit}
                    variant="contained"
                    size="small"
                    style={{ width: 200, height: 40, backgroundColor: primary_color, color: '#fff', marginRight: 5 }}
                    onClick={async () => {
                        props.setShowModal(false)
                    }}>
                    NÃO
                </Button>
            </div >

            <div id='div_opts2' style={{ width: '100%', backgroundColor: '#fff', borderRadius: 5, display: 'flex', flexDirection: 'column', display: 'none' }}>
                <div style={{ fontSize: '1.3em', fontWeight: 'bold', marginBottom: 30 }}>A etapa foi finalizada com sucesso? {(tmpStateStep?.state && tmpStateStep?.state !== '') ? <>({tmpStateStep?.state === 'OK' ? 'SIM' : 'NÃO'})</> : null}</div>
                <div id='btns-step-finish' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                    <Button
                        className={styles.btn_submit}
                        variant="contained"
                        size="small"
                        style={{ width: 200, height: 40, backgroundColor: primary_color, color: '#fff', marginRight: 5 }}
                        onClick={async () => {
                            setTempStateStep(old => {
                                return { ...old, state: 'OK' }
                            })
                            window.document.getElementById('step-notes').style.display = 'block'
                            window.document.getElementById('btns-step-finish').style.display = 'none'
                        }}>
                        SIM
                    </Button>

                    <Button
                        className={styles.btn_submit}
                        variant="contained"
                        size="small"
                        style={{ width: 200, height: 40, backgroundColor: primary_color, color: '#fff', marginRight: 5 }}
                        onClick={async () => {
                            setTempStateStep(old => {
                                return { ...old, state: 'incomplete' }
                            })
                            window.document.getElementById('step-notes').style.display = 'block'
                            window.document.getElementById('btns-step-finish').style.display = 'none'
                        }}>
                        NÃO
                    </Button>
                </div>

                <div id='step-notes' style={{ width: '100%', display: 'none' }}>
                    <textarea
                        style={{ width: '100%', height: 100, padding: 5, border: '#c3c3c366 solid 1px' }}
                        autoFocus
                        placeholder={tmpStateStep.state === 'OK' ? 'OBS. (opcional)' : 'Descreva o motivo.'}
                        onChange={(e) => {
                            setTempStateStep(old => {
                                return { ...old, notes: e.target.value }
                            })
                        }}
                        value={tmpStateStep.notes} />
                    <Button
                        className={styles.btn_submit}
                        variant="contained"
                        size="small"
                        style={{ width: 200, height: 40, backgroundColor: primary_color, color: '#fff', marginRight: 5 }}
                        onClick={async () => {

                            const nomeEtapa = props.itemsToList[props.etapaAtual - 1].title
                            const usuarioLogado = props.loggeduser

                            const response = await api_bd.put(`/tickets/${props.idTicket}`, {
                                situacao: props.etapaAtual === (props.itemsToList.length - 2) ? { titulo: 'Aguardando auditoria', descricao: '' } : navigation_state.data.situacao,
                                flowNodes: {
                                    ...navigation_state.data.flowNodes,
                                    actualState: navigation_state.data?.flowNodes.actualState > (props.etapaAtual + 1) ? navigation_state.data?.flowNodes.actualState : (props.etapaAtual + 1),
                                    steps: navigation_state.data.flowNodes.steps.map((step) => {
                                        if (step.title === nomeEtapa) {
                                            let updatedStep = {
                                                ...step,
                                                state: tmpStateStep.state,
                                                notes: tmpStateStep.notes,
                                                date_end: Date.now(),
                                                user: {
                                                    id: usuarioLogado.id,
                                                    name: usuarioLogado.nome
                                                }
                                            }
                                            return updatedStep
                                        } else {
                                            return step
                                        }
                                    })
                                }
                            })

                            set_navigation_state({
                                page_content: 'ticket_detail',
                                data: { ...response.data[0] }
                            })

                            props.set_situacao_chamado(response.data[0].situacao)

                            props.setShowModal(false)

                        }}>
                        Salvar
                    </Button>
                </div>
            </div>
        </div >
    )

}

export default ConteudoEtapas