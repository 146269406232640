import React from 'react';
// import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';

/* Níveis
1 - Cliente (que teria acesso apenas as informações, sem ediçao)
2 - Técnico (que teria acesso as informações e cadastros das informações de acesso)
3 - Admin (teria acesso as informações, cadastro de acessos e cadastros de clientes)
*/

export const SidebarData = [
  {
    title: 'Início',
    path: '/',
    icon: <AiIcons.AiFillHome />,
    cName: 'nav-text',
    nivel: 'Cliente'
  },
  {
    title: 'Cad. Cliente',
    path: '/cadclient',
    icon: <IoIcons.IoIosPersonAdd />,
    cName: 'nav-text',
    nivel: 'Super-Admin'
  },
  ,
  {
    title: 'Cad. User',
    path: '/caduser',
    icon: <IoIcons.IoIosPersonAdd />,
    cName: 'nav-text',
    nivel: 'Técnico'
  },
  {
    title: 'Logout',
    path: '/logout',
    icon: <IoIcons.IoIosLogOut />,
    cName: 'nav-text',
    nivel: 'Cliente'
  }
];
