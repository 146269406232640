import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import * as IoIcons from 'react-icons/io';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


import Styles, { primary_color } from '../Styles'

import { api_bd } from '../../services/Api'

import { msToDateTime } from '../../util/fns'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'


function FormDataCalendar(props) {
    const styles = Styles()
    const profile_data = useRecoilValue(profileData)

    const [device_height, set_device_height] = useState(null)
    const [device_width, set_device_width] = useState(null)

    const [dates, set_dates] = useState([])
    // const [selected_date, set_selected_date] = useState(null)
    const [list_users, set_list_users] = useState(null)
    // const [selected_user, set_selected_user] = useState(null)
    const [new_date, set_new_date] = useState({
        idu: '',
        data: '',
        nome: '',
        contato: '',
        feriado: false,
        janela_manutencao: false,
        descricao: '',
        cod_id: ''
    })

    const load_users = async () => {
        const lista = await api_bd.get('/users')
        set_list_users(lista.data.filter(user => user.nivel.match(/Super|Técnico/) && user.ativo === true).sort((a, b) => a.nome.localeCompare(b.nome)))
    }

    const load_dates = async () => {
        const resp = await api_bd.get('/suport_calendar')
        const resp_alphabeltical = resp.data.sort(function (a, b) {
            if (new Date(a.data).getTime() < new Date(b.data).getTime()) return 1;
            if (new Date(b.data).getTime() < new Date(a.data).getTime()) return -1;
            return 0;
        });
        set_dates(resp_alphabeltical)
    }


    const add_new_date = (key, value) => {
        let temp = { ...new_date }
        temp[key] = value
        set_new_date(temp)
    }


    useEffect(() => {
        (async () => {
            await load_users()
            await load_dates()
        })()

        const dw = document.documentElement.clientWidth
        const dh = document.documentElement.clientHeight
        set_device_width(dw)
        set_device_height(dh)


        //verificar agendamento de janela por tipo
        if (props.eventType && props.eventType.toLowerCase() === 'janela de manutenção') {
            add_new_date('janela_manutencao', true)
        }

        if (props.eventType && props.eventType.toLowerCase() === 'feriado') {
            add_new_date('feriado', true)
        }

        if (props.cod_id) {
            add_new_date('cod_id', props.cod_id)
        }
    }, [])



    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: "100%", alignItems: 'center', padding: 15, overflowY: 'auto' }}>

            <div style={{ fontSize: '1.3em', marginBottom: 30, width: '100%', fontWeight: 'bolder' }}>Adicionar evento ao calendário<hr /></div>

            <TextField
                style={{ width: '100%' }}
                id="date"
                label="Calendário"
                type="date"
                defaultValue=''

                InputLabelProps={{
                    shrink: true,
                }}

                onChange={(e) => {
                    // set_selected_date(e.target.value)
                    add_new_date('data', new Date(`${e.target.value} 00:00:00`))
                }}
            />

            {list_users && list_users.length > 0 &&
                <FormControl style={{ width: '100%', marginTop: 20 }}>
                    <InputLabel htmlFor="colaborador">Colaborador</InputLabel>
                    <Select
                        value={new_date.idu}
                        id="colaborador"
                        onChange={e => {
                            const usuario = list_users.filter(user => user.id === e.target.value)
                            const { id, nome, contato } = usuario[0]
                            let temp = { ...new_date }
                            temp['idu'] = id
                            temp['nome'] = nome
                            temp['contato'] = contato
                            set_new_date(temp)
                        }}
                    >
                        {list_users.map(user => (<MenuItem key={`${user._id}`} value={user.id}>{user.nome}</MenuItem>))}
                    </Select>
                </FormControl>
            }

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%', marginTop: 10 }}>
                <TextField
                    className={styles.form_input}
                    onChange={e => {
                        add_new_date('descricao', e.target.value)
                    }}
                    label="Descrição"
                    value={new_date.descricao}
                    id="descricao"
                    multiline={true}
                />
            </div>


            {!props.eventType &&
                <>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%', marginTop: 10 }}>
                        <Checkbox
                            onChange={(e) => {
                                console.log(e.target.checked)
                                add_new_date('feriado', e.target.checked ? true : false)
                            }}
                            style={{ marginLeft: -15 }}
                            checked={new_date.feriado}
                            color="default"
                        /><span style={{ marginLeft: 0 }}>Feriado: </span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
                        <Checkbox
                            onChange={(e) => {
                                console.log(e.target.checked)
                                add_new_date('janela_manutencao', e.target.checked ? true : false)
                            }}
                            style={{ marginLeft: -15 }}
                            checked={new_date.janela_manutencao}
                            color="default"
                        /><span style={{ marginLeft: 0 }}>Janela de Manutenção: </span>
                    </div>
                </>
            }



            <Button
                style={{ width: '100%', marginTop: 25 }}
                className={styles.btn_submit}
                variant="contained"
                color="primary"
                size="small"
                disabled={(new_date.data !== '' && new_date.nome !== '') ? false : true}
                onClick={async () => {
                    const resp = await api_bd.post('/suport_calendar', new_date)
                    set_dates(resp.data)
                    set_new_date({
                        idu: '',
                        data: '',
                        nome: '',
                        contato: '',
                        feriado: false,
                        janela_manutencao: false,
                        descricao: ''
                    })
                }}
            >
                Salvar
            </Button>

        </div>
    );
}

export default FormDataCalendar;