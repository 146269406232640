import React, { useRef } from 'react'

function FileUploader(props) {

    const {set_file, preview_src} = props

    const fileInput = useRef(null)
    const allowedMimes = [
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "image/gif"
    ]

    const handleFileInput = (e) => {
        // handle validations
        const file = e.target.files[0];

        if(preview_src){
            // preview_src(e.target.value)
            var reader = new FileReader();
            reader.onload = function(f) {
                preview_src(f.target.result)
            }
            if (file){
                reader.readAsDataURL(file);
            }
            
        }

        if (file && file.size > (2 * 1024 * 1024)) {
            console.log('Arquivo maior que 2')
        } else if (file && allowedMimes.includes(file.type)) {
            set_file(file)
        } else {
            console.log("Invalid file type")
        }
    };

    return (
        <div className="file-uploader">
            <input type="file" onChange={handleFileInput} />
            <button onClick={e => fileInput.current && fileInput.current.click()} className="btn btn-primary" />
        </div>
    )
}

export default FileUploader;