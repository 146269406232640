import React, { useRef } from 'react'

function FileUploaderMulti(props) {

    const {set_files, preview_src} = props

    const fileInput = useRef(null)
    const allowedMimes = [
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "image/gif"
    ]

    const handleFileInput = (e) => {
        // handle validations
        const files = e.target.files;

        // if(preview_src){
        //     // preview_src(e.target.value)
        //     var reader = new FileReader();
        //     reader.onload = function(f) {
        //         preview_src(f.target.result)
        //     }
        //     if (file){
        //         reader.readAsDataURL(file);
        //     }
            
        // }


        set_files(files)

        // if (files && files.size > (2 * 1024 * 1024)) {
        //     console.log('Arquivo maior que 2')
        // } else if (files && allowedMimes.includes(files.type)) {
        //     set_files(files)
        // } else {
        //     console.log("Invalid file type")
        // }
    };

    return (
        <div className="file-uploader">
            <input type="file" multiple={true} onChange={handleFileInput} />
            <button onClick={e => fileInput.current && fileInput.current.click()} className="btn btn-primary" />
        </div>
    )
}

export default FileUploaderMulti;