import React, {
    useState,
    useEffect
} from 'react';

// import * as IoIcons from 'react-icons/io';

// import { Card } from '@material-ui/core';
// import CardHeader from '@material-ui/core/CardHeader';
// import CardContent from '@material-ui/core/CardContent';

// import TextField from '@material-ui/core/TextField';

import Styles from '../components/Styles'
// import { api_bd, getUserData } from '../services/Api'
// import FormDataStation from '../components/Forms/FormDataStation'


// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';

import { useRecoilState, useRecoilValue } from 'recoil'
import { profileData } from '../atoms/user.atom'
import clientDetailsData from '../atoms/client_details.atom'


const { backend_svg_files } = require('../config.json')

function NetworkMap(props) {
    const styles = Styles()

    const [data_client, set_data_client] = useRecoilState(clientDetailsData)
    const profile_data = useRecoilValue(profileData)

    const [src_image, set_src_image] = useState(false)

    const [device_height, set_device_height] = useState(null)

    const [image_height, set_image_height] = useState(null)
    const [image_width, set_image_width] = useState(null)

    const [show_loading, set_show_loading] = useState(true)

    useEffect(() => {

        const dw = document.documentElement.clientWidth
        const dh = document.documentElement.clientHeight
        // set_device_width(dw)
        set_device_height(dh)

        set_image_height(dh - 110)
        set_image_width(dw)

        // console.log(data_client)
        if (Object.keys(data_client).length > 0) {
            set_src_image(`${backend_svg_files}/${data_client.user_sgi.toLowerCase()}.svg`)
        } else {
            set_src_image(localStorage.getItem('IDMR'))
        }

    }, [])


    return (
        <div className="flex flexRow" style={{ width: "100%", minHeight: device_height - 50, maxHeight: device_height - 50 }}>
            <div
                className="flex flexCol"
                style={{
                    padding: 30,
                    width: "100%",
                    overflowY: "auto"
                }}
            >
                {show_loading &&
                    <div className="flex" style={{ justifyContent: 'center', alignItems: 'center', width: image_width, maxWidth: image_width, height: image_height, maxHeight: image_height }}>
                        <div className="loader" style={{ width: 50, height: 50, marginRight: 10 }}></div>
                        <h2>Carregando mapa, por favor aguarde...</h2>
                    </div>
                }
                {src_image &&
                    <img
                        src={src_image}
                        onLoad={() => {
                            set_show_loading(false)
                            localStorage.setItem('IDMR', null)
                        }}
                        style={{ width: image_width, maxWidth: image_width, height: image_height, maxHeight: image_height, visibility: show_loading ? 'hidden' : 'visible' }} />
                }
            </div>

            <div onContextMenu={() => false} onDragStart={() => false} onselectstart={() => false} onSelect={() => false}
                style={{
                    position: 'absolute',
                    top: 80,
                    right: 50
                }}>
                <button
                    className="material-icons"
                    style={{
                        backgroundColor: 'Transparent',
                        backgroundRpeat: 'no-repeat',
                        color: '#3c5ca6',
                        border: 'none',
                        overflow: 'hidden',
                        outline: 'none',
                        fontSize: 35,
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        const new_width = image_width + (image_width * 0.20)
                        const new_height = image_height + (image_height * 0.20)
                        set_image_width(new_width)
                        set_image_height(new_height)
                    }}
                >zoom_in</button>
                <button
                    className="material-icons"
                    style={{
                        marginLeft: 10,
                        backgroundColor: 'Transparent',
                        backgroundRpeat: 'no-repeat',
                        color: '#3c5ca6',
                        border: 'none',
                        overflow: 'hidden',
                        outline: 'none',
                        fontSize: 35,
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        const new_width = image_width - (image_width * 0.20)
                        const new_height = image_height - (image_height * 0.20)
                        set_image_width(new_width)
                        set_image_height(new_height)
                    }}
                >zoom_out</button>
            </div>
        </div >
    );
}

export default NetworkMap


