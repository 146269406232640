import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';

import Styles, { primary_color } from './Styles'

import { api_bd } from '../services/Api'

import {
    // useRecoilState, 
    // useRecoilValue, 
    useSetRecoilState
} from 'recoil'
import navigationState from '../atoms/navigate_state.atom'

const { api_backend_address } = require('../config.json')


function TicketPopup(props) {
    const styles = Styles();
    const [show_options, set_show_options] = useState({ implantacoes: false, suportes: false, servicos_cliente: false })
    const [show_groups, set_show_groups] = useState(false)

    const [device_width, set_device_width] = useState(null)
    const [device_height, set_device_height] = useState(null)

    const set_navigation_state = useSetRecoilState(navigationState)

    const ticket_id = props.ticket_id
    const handle_close = props.handle_close

    const [categoria, set_categoria] = useState({
        categoria: 'SELECIONE',
        tipo_solicitacao: ''
    })

    const handle_show_option = (_chave, _valor) => {
        const val = { implantacoes: false, suportes: false, servicos_cliente: false }
        val[_chave] = _valor
        set_show_options(val)
    }

    useEffect(() => {
        const dw = document.documentElement.clientWidth
        const dh = document.documentElement.clientHeight
        set_device_width(dw)
        set_device_height(dh)
    }, [])

    const style_div_cat = {
        fontSize: '1.2em',
        width: (device_width - 214) * 0.7,
        minWidth: (device_width - 214) * 0.7,
        maxWidth: (device_width - 214) * 0.7
    }

    const OptionsGroup = (props) => {

        const catt = props.catt
        const options = props.options
        const title = props.title

        return (
            <div style={style_div_cat}>
                <div
                    style={{
                        cursor: "pointer",
                        width: "100%",
                        borderBottom: "1px #fff solid",
                        // backgroundColor: '#f5f5f5', 
                        height: 30,
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 'bold'
                    }} className="flex"
                    onClick={() => { handle_show_option(catt, !show_options[catt]) }}
                >
                    {title}
                </div>
                <div
                    className={`${show_options[catt] ? "accordion expanded" : "accordion"}`}
                    style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                    <div className="flex flexRow justify-content-sb borda_acima_2_filho" style={{ paddingTop: 5 }}>
                        <div style={{ width: (device_width - 214) * 0.7 }}>
                            {options.map(option => (
                                <div
                                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', wordWrap: 'break-word', fontSize: 12, paddingLeft: 10, height: 25, verticalAlign: 'center' }}
                                    onMouseOver={(e) => {
                                        e.target.style.backgroundColor = '#f5f5f5'
                                    }}
                                    onMouseLeave={(e) => {
                                        e.target.style.backgroundColor = '#fff'
                                    }}
                                    onClick={() => {
                                        set_categoria({
                                            categoria: option,
                                            tipo_solicitacao: title
                                        })
                                        set_show_options({ implantacoes: false, suportes: false, adequacoes: false, migracoes: false })
                                    }}
                                >{option}</div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', margin: 20, backgroundColor: 'white', padding: 30, minHeight: '30vh', width: '30%', borderRadius: 5 }}>
            <div style={{ cursor: "pointer", width: "100%", borderBottom: "1px #fff solid", justifyContent: 'center', fontWeight: 'bold', fontSize: '1em' }} className="flex" >
                DEFINA A CATEGORIA
            </div>
            <div
                style={{ cursor: "pointer", marginTop: 35, width: "100%", borderBottom: "1px #fff solid", borderBottom: ' 1px solid #000', height: 20 }} className="flex"
                onClick={() => { set_show_groups(!show_groups) }}
            >
                {categoria.categoria}
            </div>

            <div
                className={show_groups ? "accordion expanded" : "accordion"}
                style={{ display: "flex", flexDirection: "column", overflow: "hidden", paddingTop: 10 }}>
                <OptionsGroup title='IMPLANTAÇÕES' catt='implantacoes' options={['Implantação']} />
                <OptionsGroup title='SUPORTES' catt='suportes' options={['Suporte']} />
                <OptionsGroup title='ADEQUAÇÕES' catt='adequacoes' options={['Adequação']} />
                <OptionsGroup title='MIGRAÇÕES' catt='migracoes' options={['Migração']} />
                {/* <OptionsGroup title='SERVIÇO AO CLIENTE' catt='servicos_cliente' options={['Ativação', 'Desativação', 'Downgrade', 'Falha acesso/serviço', 'Liberação de prefixo', 'Suporte', 'Upgrade']} /> */}
            </div>

            <div style={{ display: 'flex', width: "100%", justifyContent: 'center', marginTop: 20 }}>
                <Button
                    className={styles.btn_submit}
                    // style={{ display:'flex', justifyContent:'center', alignItems: 'center', width: 160}}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={async () => {
                        const resp = await api_bd.put(`${api_backend_address}tickets/${ticket_id}`, categoria)
                        set_navigation_state({ page_content: 'ticket_detail', data: resp.data[0] })
                        handle_close()
                    }}
                >Salvar</Button>
            </div>

        </div>
    );
}

export default TicketPopup;