import React from 'react';

export const copyToClipBoard = async (_text, _pop = false) => {
    try {
        await navigator.clipboard.writeText(_text);
        if (_pop) {
            alert('Copiado!');
        }
    } catch (err) {
        console.log(err)
        alert('Falha ao copiar.');
    }
};


const HidePass = (props) => {
    const { _pass, _txt, _style } = props
    return (
        <div
            style={_style ? _style : {}}
            onClick={() => {
                if (_pass) {
                    copyToClipBoard(_pass)
                }
            }}
        >
            <b>{_txt ? _txt : ''} </b>{_pass.replaceAll(/[^ ]/g, '*')}
        </div>
    )
}

export const msToDateTime = (ms) => {
    const d = new Date(ms)
    const dia_s = {
        0: 'domingo',
        1: 'segunda',
        2: 'terça',
        3: 'quarta',
        4: 'quinta',
        5: 'sexta',
        6: 'sábado'
    }
    const ret = {
        n_dia_semana: d.getDay(), // dia 0 = domingo
        dia_semana: dia_s[d.getDay()],
        data: `${d.getDate() < 10 ? '0' : ''}${d.getDate()}`,
        mes: `${(d.getMonth() + 1) < 10 ? '0' : ''}${d.getMonth() + 1}`,
        ano: d.getFullYear(),
        hora: d.getHours(),
        minuto: d.getMinutes(),
        segundos: d.getSeconds(),
        iso_i:`${d.getFullYear()}-${(d.getMonth()+1) < 10 ? '0': ''}${d.getMonth()+1}-${d.getDate() < 10 ? '0': ''}${d.getDate()} ${d.getHours() < 10 ? '0' : ''}${d.getHours()}:${d.getMinutes() < 10 ? '0' : ''}${d.getMinutes()}:${d.getSeconds() < 10 ? '0' : ''}${d.getSeconds()}`,
        iso2: d.toISOString()
    }
    return ret
}

export default HidePass;